@import 'src/scss/mixins';
@import 'src/scss/cui/variables';
@import 'src/scss/_variables';

.autopilot-tab {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  &.disabled {
    position: relative;
  }

  &__disabled-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 90%;
    background: #eeeeee;
    z-index: 2;
    border-radius: 16px;
  }

  & > .card {
    position: absolute;
    z-index: 2;
    width: 359px;
    min-height: 156px;
    top: calc((100% - 156px) / 2);
    left: calc((100% - 359px) / 2);
    padding: 24px;
    border: 2px solid $cookunity-gray-3;

    .preferences-item__header-title {
      @include font-build(
        $cui-font-family-tt-norms,
        $cui-font-2xl,
        32px,
        $cui-font-bold
      );
    }

    .preferences-item__header-subtitle {
      @include font-build(
        $cui-font-family-tt-norms,
        $cui-font-base,
        $cui-line-height-2xl,
        450
      );
      color: $cookunity-black-secondary;
    }

    @media (max-width: $desktop) {
      top: 100px;
      left: calc((100% - 327px) / 2);
      padding: 16px;
      width: calc(100% - 16px);
      max-width: 327px;
      min-height: 124px;

      .preferences-item__header-title {
        @include font-build(
          $cui-font-family-tt-norms,
          $cui-font-xl,
          $cui-line-height-2xl,
          $cui-font-bold
        );
      }

      .preferences-item__header-subtitle {
        @include font-build(
          $cui-font-family-tt-norms,
          $cui-font-sm,
          $cui-line-height-xl,
          450
        );
        color: $cookunity-black-secondary;
      }
    }

    @media (max-width: 375px) {
      left: 8px;
    }
  }

  &__section {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__questions-wrapper {
      display: flex;
      gap: 8px;

      &.grid {
        flex-wrap: wrap;

        @media screen and (max-width: $mobile) {
          & > div {
            width: 100%;
          }
        }

        @media screen and (min-width: $mobile) and (max-width: $tablet) {
          & > div:not(:last-child) {
            width: calc((100% - 16px) / 2);
          }

          & > div:last-child {
            width: 100%;
          }
        }

        @media screen and (min-width: $tablet) {
          & > div:not(:last-child) {
            width: calc((100% - 8px) / 2);
          }

          & > div:last-child {
            width: 100%;
          }
        }
      }

      &.list {
        flex-direction: column;
      }

      @include mobile {
        gap: 16px;
      }
    }
  }
}
