.title-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: start;
  gap: 12px;
  max-width: 631px;

  .title,
  .subtitle {
    text-align: left;
  }
}
