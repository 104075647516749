@import '../../../../scss/mixins';
@import '../../../../scss/variables';
@import '../../../../scss/cui/variables';

.plan-option--card {
  padding: 25px 20px 25px 24px;
  display: flex;
  gap: 10px;
  box-sizing: border-box;
  border: 1px solid transparent;
  border-radius: 8px;

  &:has(.option-card--button) {
    padding-left: 12px;
    box-shadow: 0px 0px 6px 0px rgba(35, 31, 32, 0.1),
      0px 2px 6px 0px rgba(35, 31, 32, 0.05);
  }

  &.selected {
    border: 1px solid $cui-darkgreen-color;

    .option-card--button {
      border: 1px solid $cui-darkgreen-color;

      div {
        background-color: $cui-darkgreen-color;
      }
    }
  }

  .option-card {
    &--content {
      flex-grow: 1;
      display: flex;
      justify-content: space-between;

      .text {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: space-between;
        text-align: left;
      }
    }

    &--button {
      border: 1px solid $cookunity-gray-3;
      margin-top: 1px;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      flex-grow: 0;
      display: flex;
      flex-shrink: 0;

      div {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        align-self: center;
        margin: auto;
      }
    }
  }

  .meals-per-week {
    font-family: 'TT Norms Pro';
    font-weight: 700;
    line-height: 24px;

    span {
      font-size: 14px;
      color: $cookunity-gray-2;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.2px;
    }
  }

  .price {
    text-align: right;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
  }

  .price-per-meal {
    font-size: 14px;
    line-height: 20px;
  }

  .fees-and-taxes {
    font-size: 12px;
    text-align: right;
  }
}
