@import '../../../../scss/mixins';
@import '../../../../scss/variables';

.cookunity__my_account_form {
  width: 100%;

  .cookunity__form_row {
    width: 100%;
    display: flex;
    flex-direction: row;

    & > *:not(:last-child) {
      margin-right: 30px;
      @include mobile {
        margin: 0;
      }
    }

    & > * {
      flex-grow: 1;
    }
    @include mobile {
      flex-direction: column;
    }
  }

  .cookunity__form_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
    @include mobile {
      grid-template-columns: 1fr;
      grid-column-gap: 0;
    }
  }

  .cookunity__form_column {
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;
    min-width: 0;
    @include mobile {
      padding-bottom: 30px;
    }
  }

  .cookunity__form_title_container {
    display: flex;
    align-items: center;
    margin: 0 0 16px;

    & > .cookunity__form_title_personalize {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      letter-spacing: 0.2px;
      color: #231f20;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin: 0;
    }

    & > .label {
      background-color: $cookunity-gray-5;
      border-radius: 4px;
      margin: 0 10px;
      font-size: 10px;
      line-height: 18px;
      padding: 1px 8px;
      color: $cookunity-gray-1;
      text-transform: uppercase;
    }
  }

  .cookunity__form_label {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: $cookunity-black;
    margin: 0 0 8px;

    &.strong {
      font-family: Gilroy, Roboto, sans-serif;
    }

    // &.inline {
    //   display: inline-block;
    // }
  }

  .cookunity__form_hint {
    font-family: Roboto, sans-serif;
    font-size: 12px;    
    line-height: 20.4px;
    color: $cookunity-black;
    margin-top: 8px; 
    
    &.strong {
      font-family: Gilroy, Roboto, sans-serif;
    }
  }

  .cookunity__button_row {
    display: flex;

    & > button:first-child {
      margin: 0 8px 0 0;
    }
    @include mobile {
      flex-direction: column;

      & > button {
        width: 100%;
        display: block;
      }

      & > button:first-child {
        margin-bottom: 0 0 8px 0;
      }
    }
  }

  .cookunity__input_field {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 24px;

    border: 1px solid #dddddd;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 12px 16px;

    &[type='checkbox'] + .cookunity__form_label,
    &[type='radio'] + .cookunity__form_label {
      cursor: pointer;
    }

    &[type='checkbox'] {
      display: none;
      width: 0;
    }

    &[type='radio'] {
      display: none;
    }
  }

  .cookunity__input_checkbox_container {
    display: inline-block;
    margin-right: 10px;

    .cookunity__input_checkbox {
      &::before {
        content: '\2714';
      }
      cursor: pointer;
      border: 1px solid $cookunity-gray-3;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 18px;
      height: 18px;
      vertical-align: bottom;
      color: transparent;
      font-size: 14px;
      padding-top: 4px;

      &.checked {
        color: $cookunity-secondary-dark-green;
      }
    }
  }

  .cookunity__input_radio_container {
    display: inline-block;
    margin-right: 10px;

    .cookunity__input_radio {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      border: 1px solid $cookunity-gray-3;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      .checkmark {
        width: 10px;
        height: 10px;
        background-color: $cookunity-primary-wine;
        border-radius: 50%;
        opacity: 0;
      }

      &.checked {
        .checkmark {
          opacity: 1;
        }
      }
    }
  }

  .cookunity__form_error_message {
    font-family: Roboto, sans-serif;
    color: $cookunity-secondary-red;
    font-family: 14px;
    line-height: 16px;
    margin-top: 8px;
  }

  .dietary-dropdown-container {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: $cookunity-black;
    .dietary-dropdown__control {
      min-height: 48px;
      border: 1px solid $cookunity-gray-3;
      border-radius: 4px;

      &--is-focused {
        border: 1px solid $cookunity-gray-3;
        box-shadow: none;
      }

      .dietary-dropdown__dropdown-indicator {
        color: $cookunity-black;
        cursor: pointer;
      }

      .dietary-dropdown__clear-indicator,
      .dietary-dropdown__indicator-separator {
        display: none;
      }

      .dietary-dropdown__multi-value {
        border-radius: 4px;
        background-color: #ffffff;
        border: 1px solid $cookunity-black;
        height: 32px;
        font-size: 14px;
        line-height: 22px;
        font-family: Gilroy, Roboto, sans-serif;
        display: flex;
        align-items: center;
        padding: 7px 10px;
        font-weight: bold;

        .dietary-dropdown__multi-value__label {
          padding-left: 0;
        }

        .dietary-dropdown__multi-value__remove {
          padding-right: 0;
          cursor: pointer;

          &:hover {
            color: $cookunity-black;
            background-color: #ffffff;
          }
        }
      }
    }

    .dietary-dropdown__menu {
      z-index: 3;
      color: $cookunity-black;
      margin-top: -2px;
      border: 1px solid rgba(0, 0, 0, 0.08);
      box-shadow: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      .dietary-dropdown__option--is-focused,
      .dietary-dropdown__option--is-selected {
        color: $cookunity-black;
        background-color: $cookunity-gray-3;
        cursor: pointer;
      }
    }
  }
}
