@import '../../scss/mixins';
@import '../../scss/cui/variables';

.overlay.pause-deflection-modal > .content.large_size {
  button.close {
    display: flex;
    justify-content: flex-end;
    svg path {
      fill: black;
    }
  }

  @include tablet {
    width: 613px;
  }

  .title {
    font-family: $cui-font-family-source-serif;
    font-weight: $cui-font-bold;
    font-size: $cui-font-3xl;
    line-height: $cui-line-height-4xl;
    text-align: center;

    @include tablet {
      font-size: $cui-font-4xl;
      line-height: $cui-line-height-5xl;
    }
  }

  .options {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 20px;

    @include tablet {
      margin-top: 28px;
    }

    .option {
      width: 327px;
      background-color: $cui-black-color-100;
      box-shadow: 0px 0px 2px rgba(35, 31, 32, 0.1),
        0px 2px 2px rgba(0, 0, 0, 0.05);
      border-radius: 3px;
      display: flex;

      @include tablet {
        width: 100%;
      }

      .left {
        min-width: 116px;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }

      .reschedule-image {
        background-size: 320px;
        background-position: -90px;
      }

      .change-plan-image {
        background-size: 280px;
        background-position: -82px;
      }

      .change-address-image {
        background-size: 310px;
        background-position: -95px;
      }

      .right {
        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 25px;
        padding-right: 6px;

        @include tablet {
          width: 100%;
          padding-right: 16px;
        }

        .title {
          font-family: $cui-font-family-source-serif;
          font-style: normal;
          font-weight: $cui-font-bold;
          font-size: $cui-font-lg;
          line-height: 22px;
          text-align: left;
          margin-bottom: 2px;

          @include tablet {
            font-size: $cui-font-xl;
          }
        }

        .subtitle {
          font-family: $cui-font-family-tt-norms;
          font-style: normal;
          font-weight: $cui-font-normal;
          font-size: $cui-font-xs;
          line-height: $cui-line-height-base;
          color: $cui-black-color-400;
          white-space: pre-wrap;

          @include tablet {
            white-space: nowrap;
            color: $cui-black-color-400;
          }

          &.subtitle-2 {
            @include tablet {
              padding-top: 13px;
            }
          }
        }

        .footer {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          @include tablet {
            flex-direction: row;
            justify-content: space-between;
          }
        }

        .subtitle-highlight {
          font-family: $cui-font-family-tt-norms;
          font-style: normal;
          font-weight: $cui-font-normal;
          font-size: $cui-font-xs;
          line-height: $cui-line-height-base;

          @include tablet {
            font-weight: bold;
            color: $cui-black-color-400;
            max-width: 200px;
          }
        }

        .divider {
          border: 0.5px solid $cui-black-color-200;
          border-top: 0;
          opacity: 0.5;
          margin-top: 12px;
          margin-bottom: 8px;
          width: 158px;

          @include tablet {
            display: none;
          }
        }

        .cta {
          font-family: $cui-font-family-tt-norms;
          font-style: normal;
          font-weight: $cui-font-bold;
          font-size: $cui-font-xs;
          line-height: $cui-line-height-base;
          text-decoration-line: underline;
          color: $cui-wine-color-500;

          @include tablet {
            font-size: $cui-font-sm;
          }
        }
      }
    }
  }

  .skip-order-cta-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 42px;

    @include tablet {
      justify-content: flex-end;
    }

    .skip-order-cta {
      font-family: $cui-font-family-tt-norms;
      font-style: normal;
      font-weight: $cui-font-bold;
      font-size: $cui-font-base;
      line-height: $cui-line-height-2xl;
      text-decoration-line: underline;
    }
  }

  .cancel-info-container {
    font-size: $cui-font-sm;
    color: $cui-black-color-400;

    @include desktop {
      display: flex;
      align-items: flex-end;
    }

    .cu-email {
      padding-left: 30px;

      @include desktop {
        padding-left: 4px;
      }
    }

    .cancel-title {
      font-weight: $cui-font-bold;
      padding-right: 4px;
      padding-left: 10px;
    }

    .cancel-subtitle {
      font-weight: $cui-font-normal;
      display: inline-block;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .pause-plan-container {
    padding-top: 2rem;
    display: flex;
    flex-direction: column-reverse;
    align-items: stretch;

    @include tablet {
      flex-direction: row;
      justify-content: flex-end;
    }

    .pause-cta {
      padding: 20px;
      border-color: $cui-black-color-400;
      background-color: $cui-white-color;
      @include tablet {
        margin-right: 20px;
      }
    }

    .cancel-cta {
      padding: 20px;
      @include mobile {
        margin-bottom: 15px;
      }
    }
  }
}
