@import 'src/scss/cui/variables';
@import 'src/scss/mixins';

.coachmark-overlay {
  position: absolute;
  z-index: 9999998;
  border-radius: 4px;
  transition: all .3s ease-out;
  box-shadow: rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px;

  .coachmark-tooltip {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: #FFF;
    border-radius: 12px;
    padding: 16px;
    min-width: 300px;


    p {
      margin-top: 0;
      margin-bottom: 10px;
      @include font-build(
        $cui-font-family-tt-norms,
        $cui-font-sm,
        $cui-line-height-base,
        $cui-font-normal
      );
    }

    &-header {
      display: flex;
      margin-bottom: 8px;
      align-items: center;
      justify-content: space-between;

      h3 {
        margin: 0;
        @include font-build(
          $cui-font-family-tt-norms,
          $cui-font-xl,
          $cui-line-height-2xl,
          $cui-font-bold
        );
      }

      &-close {
        width: 20px;
        cursor: pointer;

        path {
          fill: $cui-gray-color;
        }

        &:hover {
          path {
            fill: $cui-black-color;
          }
        }

        
      }
    }

    &-button {
      align-self: end;
    }

    &::after {
      position: absolute;
      content: " ";
      border-style: solid;
      border-width: 10px;
    }

    &.coachmark-tooltip-left {
      top: 50%;
      right: 100%;
      transform: translateY(-50%);
      margin-right: 20px;

      &::after {
        top: 50%;
        left: 100%;
        margin-top: -10px;
        border-color: transparent transparent transparent white;
      }
    }

    &.coachmark-tooltip-right {
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      margin-left: 20px;

      &::after {
        top: 50%;
        right: 100%; 
        margin-top: -10px;
        border-color: transparent white transparent transparent
      }
    }
  }
}

  .coachmark-overlay-helper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999997;
  }

