@import 'src/scss/mixins';
@import 'src/scss/variables';

.economic-plan-forever-menu-modal__desktop {
  padding: 0 !important;
  border-radius: 8px;

  .economic-plan-forever-action-buttons {
    display: flex;
    gap: 10px;
    margin-top: 32px;
    @include mobile {
      flex-direction: column;
      margin-bottom: 16px;
      width: 100% !important;
    }
  }

  .economic-plan-forever-menu-modal {
    &__content {
      display: flex;
      flex-direction: row;
      @include mobile {
        flex-direction: column;
      }
    }

    &__img-container {
      background-color: $cui-black-color-100;
      display: flex;

      border-radius: 10px;
      img {
        border-radius: 8px;
      }
    }

    &__text-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;
      padding: 0 24px;
      text-align: center;
    }
    &__title {
      text-align: left;
      @include font-build(
        $cui-font-family-tt-norms,
        $cui-font-3xl,
        $cui-line-height-4xl,
        $cui-font-bold
      );
    }
    &__subtitle {
      text-align: left;
      @include font-build(
        $cui-font-family-tt-norms,
        $cui-font-base,
        $cui-line-height-2xl,
        $cui-font-normal
      );
    }
    &__end {
      text-align: left;
      color: #a8a8a8;
      font-style: italic;
      font-size: small;
    }
  }
}

.economic-plan-forever-menu-modal__mobile {
  padding: 0 !important;
  border-radius: 8px;

  .cui-bottom-sheet__sheet__body {
    padding: 0 !important;
  }
  .economic-plan-forever-menu-modal {
    &__content {
      display: flex;
      flex-direction: column;
    }

    &__img-container {
      background-color: $cui-black-color-100;
      display: flex;
      border-radius: 10px;
      img {
        width: 100%;
        border-radius: 8px;
      }
    }

    &__text-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;
      padding: 16px;
      text-align: center;
      button {
        width: 100%;
        margin-top: 16px;
      }
    }
    &__title {
      text-align: left;
      @include font-build(
        $cui-font-family-tt-norms,
        $cui-font-2xl,
        32px,
        $cui-font-bold
      );
    }
    &__subtitle {
      text-align: left;
      @include font-build(
        $cui-font-family-tt-norms,
        $cui-font-base,
        $cui-line-height-2xl,
        $cui-font-normal
      );
    }
    &__end {
      text-align: left;
      color: #a8a8a8;
      font-style: italic;
      font-size: small;
    }
  }

  .economic-plan-forever-action-buttons {
    display: flex;
    gap: 10px;
    margin-top: 32px;
    @include mobile {
      flex-direction: column;
      width: 100% !important;
    }
  }
}
