@import '../../../../scss/cui/variables';
@import '../../../../scss/mixins';

.cookunity__new_modal__overlay
  > .cookunity__new_modal__content.feedback-after-pausing-modal {
  @include tablet {
    padding: 40px 16px;
    width: 616px;
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .modal-title {
      @include tablet {
        padding: 0 16px;
      }

      @include font-build(
        $cui-font-family-tt-norms,
        $cui-font-3xl,
        $cui-line-height-4xl,
        $cui-font-bold
      );
    }

    .modal-description {
      @include tablet {
        padding: 0 16px;
      }

      @include font-build(
        $cui-font-family-tt-norms,
        $cui-font-sm,
        $cui-line-height-base,
        $cui-font-normal
      );
    }

    .cui-meal-card-loading {
      width: 552px;
      margin: auto;
      height: 328px;
    }
  }
}
