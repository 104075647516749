@import '../../../../scss/mixins';
@import '../../../../scss/cui/variables';

.cancel_subscription {
  margin-top: 32px;

  @include tablet {
    margin-top: 0;
  }
}

.action_link {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @include tablet {
    flex-direction: row;
  }
}

.action-account {
  margin-top: 20px;
  font-family: $cui-font-family-tt-norms;
  font-style: normal;
  font-weight: 450;
  font-size: 12px;
  line-height: $cui-line-height-base;
  text-align: left;
  color: $cui-black-color-400;

  @include tablet {
    font-size: 14px;
  }
}

.action-account-help-link {
  font-weight: bold;
  text-decoration: underline;
}
