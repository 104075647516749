@import 'src/scss/variables';

.card {
  border-radius: 16px;
  padding: 16px;
  box-shadow: 0 2px 6px 0 rgba(35, 31, 32, 0.05),
    0 0 6px 0 rgba(35, 31, 32, 0.1);
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: $cookunity-white;
}
