@import '../../../../../../scss/mixins';
@import '../../../../../../scss/variables';
@import '../../../../../../scss/cui/variables';

.modal-how-can-i-skip {
  .cookunity__new_modal__overlay > &.cookunity__new_modal__content {
    @include mobile {
      padding: 40px 12px;
      height: -webkit-fill-available;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 1rem;

    height: 100%;

    padding-bottom: 1rem;

    text-align: center;

    @include desktop {
      height: auto;
      padding: 0 40px;
      padding-bottom: 0;
    }

    & > * {
      flex: 0 0 auto;
    }

    .title {
      @include font-build(
        $cui-font-family-tt-norms,
        $cui-font-2xl,
        $cui-line-height-4xl,
        $cui-font-bold
      );

      @include desktop {
        font-family: $cui-font-family-rossanova;
        font-size: $cui-font-4xl;
        line-height: $cui-line-height-5xl;
      }
    }

    .description {
      @include font-build(
        $cui-font-family-tt-norms,
        $cui-font-sm,
        $cui-line-height-xl,
        $cui-font-normal
      );
    }

    .gif-container {
      flex: 0 1 auto;
      min-height: 0;
      height: 100%;
      display: flex;
      justify-content: center;

      .gif {
        height: 100%;
        width: auto;
        overflow: hidden;
        border-radius: 8px;
        box-shadow: 0px 12px 12px 0px rgba(0, 0, 0, 0.07),
          0px 0px 12px 0px rgba(35, 31, 32, 0.1);

        @include desktop {
          max-height: 400px;
          width: 100%;
        }

        img {
          height: calc(100% + 1px); // Hide the 1px border on the bottom
          width: calc(100% + 1px); // Hide the 1px border on the right
        }
      }
    }
  }
}
