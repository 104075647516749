@import 'src/scss/mixins';

.info-container {
  width: 100%;
  max-width: 375px;
  margin: 180px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 0 16px;

  @include mobile {
    margin: 110px 0;
  }
}
