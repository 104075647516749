@import '../../scss/mixins';
@import '../../scss/variables';
@import '../../scss/cui/variables';

body:has(.cookunity__sliding_panel_wrapper) {
  overflow: hidden;
}

.cookunity__sliding_panel_wrapper {
  @include shimmer_keyframes();
  font-family: Gilroy, Roboto, sans-serif;
  font-weight: 700;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(35, 31, 32, 0.7);
  position: fixed;
  z-index: 100;
  color: $cookunity-black;

  .cookunity__sliding_panel {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: -440px;
    height: 100%;
    width: 432px;
    background-color: #ffffff;
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.04),
      0px 8px 16px rgba(0, 0, 0, 0.06);

    &.noAnimation {
      right: 0;
    }

    animation: slide 0.4s forwards;
    @keyframes slide {
      100% {
        right: 0;
      }
    }

    @media screen and (max-width: 432px) {
      width: 100%;
    }

    & > .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 34px 20px 22px 20px;
      box-sizing: border-box;
      color: $cookunity-black;
      & > * {
        margin: 0;
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
      }

      @include mobile {
        padding: 20px 13px;
      }

      .close-icon {
        align-self: flex-start;
        cursor: pointer;
        padding: 6px;
        font-size: 22px;
      }
    }

    & > .custom-header {
      display: flex;
      justify-content: flex-end;
      box-sizing: border-box;
      color: white;
      color: $cookunity-black;
      width: 100%;

      .custom-icon-close {
        position: fixed;
        z-index: 7;
        align-self: flex-start;
        cursor: pointer;
        width: 30px;
        height: 30px;
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 20px;
        color: white;
        top: 10px;
        right: 10px;
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
      }
    }

    & > .hover {
      border-radius: 0px 0px 20px 20px;
      background: linear-gradient(
        270deg,
        rgba(0, 0, 0, 0.93) 0%,
        rgba(0, 0, 0, 0.68) 42.15%,
        rgba(0, 0, 0, 0) 100%
      );
    }

    & > .content {
      flex-grow: 1;
      padding: 0 20px 40px 20px;
      box-sizing: border-box;
      overflow: auto;

      @include mobile {
        padding: 0 13px 40px 13px;
      }
    }

    .loading {
      height: 80px;
      margin: 0 20px;
      flex-grow: unset;
      @include shimmer();
    }

    & > .buttons {
      flex: 0 0 auto;
      box-sizing: border-box;
      box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.04),
        0px -8px 16px rgba(0, 0, 0, 0.06);
      padding: 16px 20px;

      button {
        width: 100%;

        &:first-child {
          margin-bottom: 16px;
        }
      }

      @include mobile {
        padding: 16px 13px;
      }
    }
  }
}

.rebranding {
  .icon-close {
    path {
      fill: $cui-black-color;
    }
  }

  .cookunity__sliding_panel_wrapper {
    z-index: 1000;

    .cookunity__sliding_panel {
      & > .header {
        & > p {
          color: $cui-black-color-500;

          @include font-build(
            $cui-font-family-source-serif,
            $cui-font-xl,
            $cui-font-3xl,
            $cui-font-bold
          );
        }
      }

      & > .content {
        .cookunity__track_delivery_panel {
          .delivery_info {
            .tracking_help {
              color: $cui-black-color-400;

              @include font-build(
                $cui-font-family-tt-norms,
                $cui-font-sm,
                $cui-line-height-xl,
                $cui-font-normal
              );
            }

            .item {
              &.tracking-item {
                .tracking_url {
                  color: $cui-black-color;

                  @include font-build(
                    $cui-font-family-tt-norms,
                    $cui-font-base,
                    $cui-line-height-2xl,
                    $cui-font-bold
                  );
                }
              }

              .title {
                color: $cui-black-color-500;

                @include font-build(
                  $cui-font-family-tt-norms,
                  $cui-font-sm,
                  $cui-line-height-xl,
                  $cui-font-normal
                );
              }

              .subtitle {
                color: $cui-black-color-500;

                @include font-build(
                  $cui-font-family-tt-norms,
                  $cui-font-base,
                  $cui-line-height-2xl,
                  $cui-font-bold
                );

                span {
                  color: $cui-black-color-400;

                  @include font-build(
                    $cui-font-family-tt-norms,
                    $cui-font-base,
                    $cui-line-height-2xl,
                    $cui-font-normal
                  );
                }

                &.tracking_msg {
                  span {
                    color: $cui-black-color-300;

                    @include font-build(
                      $cui-font-family-tt-norms,
                      $cui-font-sm,
                      $cui-line-height-xl,
                      $cui-font-normal
                    );
                  }
                }
              }
            }
          }

          .tracking_info {
            .cookunity__tracking_info {
              .tracking_step {
                .progress {
                  .completed {
                    border: 2px solid $cui-olive-green-color-500;
                    background-color: $cui-olive-green-color-500;
                  }
                }

                .description {
                  .title {
                    text-transform: uppercase;
                    color: $cui-olive-green-color-500;

                    @include font-build(
                      $cui-font-family-tt-norms,
                      $cui-font-base,
                      $cui-line-height-2xl,
                      $cui-font-bold
                    );
                  }

                  .label {
                    @include font-build(
                      $cui-font-family-tt-norms,
                      $cui-font-sm,
                      $cui-line-height-xl,
                      $cui-font-normal
                    );
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
