@import '../../scss/variables';

.textarea {
    margin: 0 0 5px 0;
    background: #ffffff;
    border: 1px solid $cookunity-gray-3;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px 15px;
    height: 80px;
    width: 100%;
    outline: none;
    color: #231f20;
    resize: none;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.2px;
}