@import '../../../../scss/mixins';
@import '../../../../scss/variables';
@import '../../../../scss/cui/variables';

.cookunity__user_menu {
  position: absolute;
  width: 325px;
  right: 30px;
  z-index: 26;

  color: $cui-black-color;
  background-color: $cui-white-color;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.04), 0 8px 16px rgba(0, 0, 0, 0.06);
  border-radius: 0 0 4px 4px;

  @include until($tablet) {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    min-height: 100%;
    z-index: 100;
    overflow: auto;
    border-radius: 0;
    border: 1px solid $cui-black-color-200;
    box-shadow: none;
  }

  .menu_header {
    display: none;
    align-items: center;
    padding: 24px 30px;

    @include mobile {
      display: flex;
      padding-block: 1.375rem;
      background-color: $cui-black-color;
      position: relative;

      & > .close_button {
        position: absolute;
        color: $cui-white-color;
      }

      .cui-logo {
        margin: auto;
        justify-self: center;
      }
    }

    @include until($tablet) {
      display: flex;
    }

    & > .close_button > svg {
      width: 24px;
      height: 24px;
    }

    & > .logo {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 150px;
        height: 27px;
        margin-left: -24px;
      }
    }
  }

  .user_info {
    @include mobile {
      padding-inline: 1.75rem;
    }

    button {
      font-family: $cui-font-family-tt-norms;
    }
  }

  .menu_section {
    padding: 0 17px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include mobile {
      padding: 14px 0;
      margin-inline: 1rem;
    }

    &.user_info {
      display: flex;
      align-items: center;
      flex-direction: row;
      border: 1px solid $cui-black-color-150;
      border-radius: 16px;
      padding: 16px;
      margin: 16px;

      @include mobile {
        padding: 24px 30px;
      }
    }

    .greeting {
      margin: 0 0 0 16px;
      color: $cui-black-color-300;

      @include font-build(
        $cui-font-family-tt-norms,
        $cui-font-sm,
        $cui-line-height-lg,
        $cui-font-normal
      );

      .user_name {
        color: $cui-black-color;
        text-transform: capitalize;

        @include font-build(
          $cui-font-family-tt-norms,
          $cui-font-xl,
          $cui-line-height-3xl,
          $cui-font-bold
        );
      }
    }

    .menu_item {
      display: flex;
      align-items: center;
      position: relative;
      gap: 1rem;
      line-height: 37px;
      color: $cui-black-color;
      padding: 4px 16px;
      width: 100%;
      cursor: pointer;

      .unity_pass_logo {
        background: #c7b273;
        border-radius: 8px;
        padding: 6px 10px;
        width: 35%;
      }

      @include font-build(
        $cui-font-family-tt-norms,
        $cui-font-base,
        $cui-line-height-4xl,
        $cui-font-normal
      );

      @include mobile {
        padding: 8px 27px 10px;
      }

      &:hover,
      &:active {
        background: $cui-black-color-100;
      }

      &_link {
        display: flex;
        align-items: center;
        gap: 1rem;
      }

      &_sublink {
        padding-left: calc(25px + 1rem);
      }

      &.logout {
        padding-block: 0.75rem;
        color: $cui-red-500;
        font-weight: 600;
        font-size: 16px;
        border-bottom: none;
      }

      i {
        margin-left: auto;
        &::before {
          color: $cui-gray-color;
        }
      }
    }
  }
}
