@import '../../scss/mixins';

.no-img-container {
    display: flex;
    justify-content: center;
    height: 100%;
    position: relative;
}

.txt-noimage {
    color: #231F20;
    position: absolute;
    z-index: 1;
    top: 50%;
    margin: 0;

    @include mobile {
        font-size: 14px;
        top: 38%;
    }
}
