@import '../../scss/mixins';
@import '../../scss/variables';

.step-container {
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
  line-height: 37px;
  color: $cui-black-color;
  padding: 20px;
  background-color: rgb(255, 255, 255);
  border-color: rgb(204, 204, 204);
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  margin-bottom: 10px;

  &.loading {
    padding: 0;

    .cui-meal-card-loading {
      height: 72px;
    }
  }

  @include font-build(
    $cui-font-family-tt-norms,
    $cui-font-base,
    $cui-line-height-4xl,
    $cui-font-normal
  );

  .checkmark {
    width: 32px;
    height: 32px;
    background-color: $cookunity-secondary-green;
    border-radius: 50%;
  }

  .pending {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border: solid 1.5px;
    border-color: $cookunity-secondary-green;
    border-radius: 50%;

    span {
      color: $cookunity-secondary-green;
    }
  }

  .disabled {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background-color: #f5f5f5;
    border-radius: 50%;

    span {
      color: #4d4d4f;
    }
  }

  .icon-separator-container {
    padding-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;

    .step-title-container {
      display: flex;

      .icon {
        width: 20px;
        height: 20px;
        margin-left: 5px;
        color: $cookunity-secondary-green;

        svg {
          height: 100%;
        }
      }
    }
  }

  .header {
    font-family: $cui-font-family-tt-norms;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    cursor: pointer;
  }

  .description {
    font-family: $cui-font-family-tt-norms;
    font-weight: 450;
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
    margin-bottom: 16px;
    display: block;
  }

  .step-body {
    font-size: 14px;
    line-height: normal;

    .first-order-container {
      .first-order-title {
        font-weight: 700;
        font-size: 16px;
        color: $cookunity-secondary-green;
        display: block;
      }

      &.description:last-child {
        margin-bottom: 0;
      }
    }
  }

  .step {
    width: 80%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
