@import '../../scss/mixins';
@import '../../scss/cui/variables';

.meal-card-slider__mobile {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  gap: 16px;
  padding-top: 6px;
  padding-left: 6px;
  padding-right: 6px;
  padding-bottom: 16px;

  .cui-card,
  .card-v2:not(.extra-small),
  .regular-bundle-card {
    scroll-snap-align: center;
    min-width: 324px;
    max-width: 324px;
    height: 450px;
    max-height: 450px;
  }
}

.meal-card-slider__desktop {
  // First try to make this re-usable
  // Inspired code from src/components/OrderPreview/OrderPreview.scss and OrderPreview.rebranding.scss
  .slick-slider {
    .slick-list {
      max-width: 100%;

      @include mobile {
        padding-block-end: 0.75rem;
      }

      .slick-track {
        margin-inline-start: 0;
        display: flex !important;

        .slick-slide {
          padding: 16px 16px 16px 0px;
          height: inherit !important;
          > div {
            height: 450px;
            max-height: 450px;
          }
        }
      }
    }

    .slick-arrow {
      opacity: 1;
      pointer-events: all;
      top: 50%;
      border-radius: unset;
      box-shadow: unset;
      background-repeat: no-repeat;
      background-size: 10px;
      background-color: $cui-lightgray-color;
      background-position: center;
      border-radius: 25px;
      height: 26px;
      width: 26px;

      &.slick-next {
        background-image: url('../../assets/svg/carousel-arrow-right.svg');
        right: -20px;
      }

      &.slick-prev {
        background-image: url('../../assets/svg/carousel-arrow-left.svg');
        left: -20px;
      }

      &::before {
        display: none;
      }

      &.slick-disabled {
        visibility: hidden;
      }
    }
  }

  @include mobile {
    display: flex;
    flex-direction: row;
    overflow: hidden;

    & > * {
      max-width: 100%;
    }
  }
}
