@import 'src/scss/mixins';
@import 'src/scss/cui/variables';
@import 'src/scss/_variables';

.radio-button-list {
  display: flex;
  gap: 30px;

  &.row {
    flex-direction: row;
  }

  &.column {
    flex-direction: column;
    gap: 16px;
  }

  &__option {
    display: flex;
    gap: 8px;
    cursor: pointer;

    input {
      width: 18px;
      height: 18px;
      cursor: pointer;
      accent-color: $cookunity-primary-wine;
    }

    label {
      cursor: pointer;
      text-align: left;
      color: $cui-black-color-500;
      @include font-build(
        $cui-font-family-tt-norms,
        $cui-font-sm,
        $cui-line-height-xl,
        $cui-font-normal
      );
    }
  }
}
