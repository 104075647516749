@import '../../../../scss/cui/variables';
@import '../../../../scss/mixins';

.card-title {
  margin: 0;
  color: $cui-black-color;
  &--medium {
    @include font-build(
      $cui-font-family-tt-norms,
      $cui-font-base,
      22px,
      $cui-font-bold
    );
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &--small {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: wrap;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    @include font-build(
      $cui-font-family-tt-norms,
      $cui-font-base,
      $cui-line-height-2xl,
      $cui-font-bold
    );
  }
  &--extra-small {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: wrap;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 40px;
    @include font-build(
      $cui-font-family-tt-norms,
      $cui-font-sm,
      $cui-line-height-xl,
      $cui-font-bold
    );
  }
  &--large {
    @include font-build(
      $cui-font-family-tt-norms,
      $cui-font-base,
      $cui-line-height-xl,
      $cui-font-bold
    );
  }
  &--new-home-layout {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    @include font-build(
      $cui-font-family-tt-norms,
      $cui-font-sm,
      $cui-line-height-xl,
      $cui-font-bold
    );
  }
}

.card-subtitle {
  margin: 0;
  color: $cui-black-color;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  &--medium,
  &--large {
    @include font-build(
      $cui-font-family-tt-norms,
      $cui-font-sm,
      $cui-line-height-xl,
      $cui-font-normal
    );
  }
  &--small {
    @include font-build(
      $cui-font-family-tt-norms,
      $cui-font-xs,
      $cui-line-height-base,
      $cui-font-normal
    );
  }
}
