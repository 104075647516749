@import '../../scss/mixins';

.content.deflection-with-active-promo.medium_size {
  padding: 0;
  height: 100%;
  display: flex;
  align-items: end;
  background-size: auto 100%;

  &.skip-deflection {
    background-image: url('../../assets/img/skip-deflection-with-promo/skip-deflection-with-promo-mobile.png');

    .deflection-content {
      @include tablet {
        min-height: 365px;
      }
    }

    @include tablet {
      background-image: url('../../assets/img/skip-deflection-with-promo/skip-deflection-with-promo-desktop.png');
    }
  }

  &.pause-deflection {
    background-image: url('../../assets/img/skip-deflection-with-promo/pause-deflection-with-promo-mobile.png');

    .deflection-content {
      @include tablet {
        min-height: 335px;
      }
    }

    @include tablet {
      background-image: url('../../assets/img/skip-deflection-with-promo/pause-deflection-with-promo-desktop.png');
    }
  }

  &.cancel-deflection {
    background-image: url('../../assets/img/skip-deflection-with-promo/cancel-deflection-with-promo-mobile.png');

    .deflection-content {
      @include tablet {
        min-height: 335px;
      }
    }

    @include tablet {
      background-image: url('../../assets/img/skip-deflection-with-promo/cancel-deflection-with-promo-desktop.png');
    }
  }

  .deflection {
    &-content {
      padding: 27px 16px 37px;
      border-radius: 10px 10px 0px 0px;
      background-color: white;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include tablet {
        border-radius: 0px 10px 10px 0px;
        padding: 75px 28px 24px 20px;
        max-width: 410px;
        gap: 20px;
      }
    }

    &-title {
      font-size: 28px;
      font-weight: 700;
      line-height: 36px;
    }

    &-description {
      font-size: 14px;
      font-weight: 450;
      line-height: 20px;
      margin-top: 15px;
    }

    &-actions {
      display: flex;
      flex-direction: column-reverse;
      margin-top: 19px;
      gap: 12px;

      @include tablet {
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 0;
      }
    }
  }

  @include tablet {
    flex-direction: column;
    height: auto;
    width: 630px;
  }
}
