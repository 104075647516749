@import '../../../../../scss/cui/variables';
@import '../../../../../scss/mixins';

.after-pausing-calendar {
  @include tablet {
    display: flex;
    justify-content: space-between;
    gap: 8px;

    .calendar-pagination {
      width: 8px;
      visibility: hidden;

      &.visible {
        visibility: visible;
      }

      button {
        cursor: pointer;
      }
    }
  }

  .calendar-weeks-scroll {
    position: relative;
    overflow: hidden;
  }

  .calendar-weeks {
    display: flex;
    flex-direction: column;
    gap: 5px;

    @include tablet {
      gap: 8px;
    }

    .calendar-week {
      display: flex;
      gap: 5px;

      @include tablet {
        gap: 8px;
      }
    }
  }
}

.calendar-box-content {
  position: relative;
  overflow: hidden;

  height: 48px;
  width: 48px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  text-align: center;

  border: 1px solid $cui-black-color;
  border-radius: 8px;

  @include font-build(
    $cui-font-family-tt-norms,
    $cui-font-xxs,
    $cui-line-height-xs,
    $cui-font-normal
  );

  @include tablet {
    width: 72px;
    height: 76px;

    font-weight: $cui-font-bold;
  }

  &.today {
    border: none;
    background-color: $cui-yellow-color-600;
  }

  &.confirmed {
    border: none;
    background-color: $cui-black-color;

    color: $cui-white-color;
  }

  &.confirmedAfter {
    border: none;
    background-color: $cui-black-color;

    color: $cui-white-color;
  }

  &.resume {
    border: none;
    background-color: $cui-light-blue-700;

    color: $cui-white-color;
  }

  &.skipped {
    background-color: $cui-black-color-100;
    color: $cui-black-color-300;
    border: none;
    position: relative;

    .cross-line {
      position: absolute;
      top: 0px;
      left: 1px;

      height: 2px;
      width: 150%;

      transform: rotate(45deg);
      transform-origin: left;

      background-color: $cui-black-color-400;
    }
  }

  .date {
    text-align: center;
    font-size: $cui-font-base;
    line-height: $cui-line-height-xl;
    font-weight: $cui-font-bold;

    @include tablet {
      font-size: $cui-font-3xl;
      line-height: $cui-line-height-4xl;
    }
  }
}

.calendar-week .calendar-box-tooltip {
  z-index: 1;

  background-color: $cui-black-color;

  font-size: $cui-font-xs;
  line-height: $cui-line-height-base;

  @include tablet {
    font-size: $cui-font-sm;
    line-height: $cui-line-height-xl;
  }
}
