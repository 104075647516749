@import 'src/scss/cui/variables';
@import 'src/scss/mixins';

.preferences-save-modal {
  &__content {
    .title {
      @include font-build(
        $cui-font-family-tt-norms,
        $cui-font-3xl,
        $cui-line-height-4xl,
        $cui-font-bold
      );
      color: $cui-black-color;
      display: flex;
      align-items: center;
      margin: 10px 0 16px 0;
      justify-content: center;
    }

    .description {
      @include font-build(
        $cui-font-family-tt-norms,
        $cui-font-base,
        $cui-line-height-2xl,
        $cui-font-normal
      );
      color: $cui-black-color;
      margin: 0;
    }

    .action_container {
      margin: 32px 0 0;
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;
      button {
        width: max-content;
      }
    }
  }
}
