@import 'node_modules/front-lib/dist/cui/styles/globals.scss';

// new colors
$cui-black-color-0: #000000;
$cui-black-color-1: #0d1c2e;
$cui-black-color-2: #231f20;
$cui-black-color-3: #1c1c1c;
$cui-white-color: #ffffff;
$cui-darkgreen-color: #199861;
$cui-gray-color: #4d4d4f;
$cui-lightgray-color: #f5f5f5;
$cui-gray-disabled-color: #abababab;
$cui-orange-color: #f96715;
$cui-feedback-color: #3939d8;
$header-height: 72px;
