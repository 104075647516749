@import 'src/scss/mixins';
@import 'src/scss/cui/variables';
@import 'src/scss/variables';

.preferences-item__header {
  display: flex;
  flex-direction: column;
  gap: 2px;

  &-title {
    color: $cui-black-color;
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    @include font-build(
      $cui-font-family-tt-norms,
      $cui-font-base,
      $cui-line-height-2xl,
      $cui-font-bold
    );
    svg {
      width: 16px;
      height: 16px;
    }
  }

  &-subtitle {
    margin: 0;
    color: $cui-black-color-400;
    @include font-build(
      $cui-font-family-tt-norms,
      $cui-font-xs,
      $cui-line-height-base,
      $cui-font-normal
    );
  }

  &-tooltip {
    color: $cui-white-color;
    @include font-build(
      $cui-font-family-tt-norms,
      $cui-font-sm,
      $cui-line-height-xl,
      450
    );
    max-width: 300px;
  }

  &-tooltip-container {
    z-index: 2;
    background-color: $cui-gray-color !important;
    border-radius: 8px !important;
    padding: 16px !important;
  }

  &.section-title {
    gap: 4px;

    .preferences-item__header-title {
      @include font-build(
        $cui-font-family-tt-norms,
        $cui-font-xl,
        $cui-line-height-2xl,
        $cui-font-bold
      );
    }

    .preferences-item__header-subtitle {
      @include font-build(
        $cui-font-family-tt-norms,
        $cui-font-sm,
        $cui-line-height-xl,
        450
      );
    }
  }

  &.question-title {
    gap: 16px;

    .preferences-item__header-title {
      @include font-build(
        $cui-font-family-tt-norms,
        $cui-font-sm,
        $cui-line-height-xl,
        $cui-font-bold
      );
    }

    .preferences-item__header-subtitle {
      color: $cookunity-black-secondary;

      @include font-build(
        $cui-font-family-tt-norms,
        $cui-font-sm,
        $cui-line-height-xl,
        450
      );

      b {
        @include font-build(
          $cui-font-family-tt-norms,
          $cui-font-sm,
          $cui-line-height-xl,
          $cui-font-bold
        );
      }
    }
  }
}
