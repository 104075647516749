@import 'src/scss/mixins';
@import 'src/scss/cui/variables';

.preferences-tabs {
  width: 100%;
  min-width: 100%;
  border-bottom: 2px solid $cui-black-color-200;
  display: flex;
  flex-direction: row;
  gap: 8px;

  &__item {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: max-content;
    padding: 11px 24px;
    border-radius: 8px 8px 0 0;
    border-bottom: 2px solid $cui-black-color-200;
    background: $cui-black-color-100;
    margin-bottom: -2px;
    text-transform: capitalize;
    @include font-build(
      $cui-font-family-tt-norms,
      $cui-font-base,
      $cui-line-height-2xl,
      $cui-font-normal
    );

    &.selected {
      border-bottom: 2px solid $cui-wine-color-500;
      font-weight: $cui-font-bold;
      color: $cui-wine-color-500;
    }
  }

  &__item:hover {
    background: $cui-black-color-150;
  }
}
