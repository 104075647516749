@import 'src/scss/mixins';
@import 'src/scss/cui/variables';

.membership {
  width: 100%;
  padding: 0;
  border-radius: 16px;

  .unity-pass-banner {
    font-family: $cui-font-family-source-serif;
    flex-direction: column-reverse;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-position: center;
    background: url('../../../../assets/img/unitypass.png') no-repeat center / 170px,
      #C7B273;
    padding-bottom: 50px;
    padding-top: 50px;
    display: flex;
  }

  .introduction-container {
    padding: 20px;

    .title-container {
      flex-direction: column;
      display: flex;

      .title {
        font-family: Rossanova;
        text-align: center;
        line-height: 42px;
        font-size: 38px;
      }

      .subtitle {
        font-family: Rossanova;
        text-align: center;
        line-height: 42px;
        color: #70707b;
        font-size: 38px;
      }
    }

    .description-container {
      font-family: TT Norms Pro;
      text-align: center;
      line-height: 24px;
      color: #4d4d4f;
      font-size: 16px;
      padding: 10px;
      padding-left: 90px;
      padding-right: 90px;
      padding-bottom: 0;
    }

    .introduction-buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 20px;
      padding-top: 15px;

      .cui-button-primary-solid {
        background-color: black;
        border: black;
        color: white;
        height: 50px;
        width: 50%;
      }
    }
  }

  @include until($tablet) {
    .cui-bottom-sheet__header {
      font-family: $cui-font-family-source-serif;
      flex-direction: column-reverse;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      background: url('../../../../assets/img/unitypass.png') no-repeat center / 170px,
        #c7b273;
      padding-bottom: 40px;
      padding-top: 10px;
      display: flex;

      .cui-bottom-sheet__header__title {
        justify-content: center;
        padding-bottom: 15px;
        align-self: center;
        display: flex;
      }

      .cui-bottom-sheet__header__close-button {
        justify-content: flex-end;
      }
    }

    .introduction-container {
      font-family: 'TT Norms Pro';
      flex-direction: column;
      line-height: 20px;
      font-weight: 450;
      font-size: 14px;
      display: flex;
      gap: 16px;

      .title-container {
        flex-direction: column;
        display: flex;

        .title {
          font-family: Rossanova;
          text-align: center;
          line-height: 42px;
          font-size: 35px;
        }

        .subtitle {
          font-family: Rossanova;
          text-align: center;
          line-height: 42px;
          color: #70707b;
          font-weight: 500;
          font-size: 34px;
        }
      }

      .description-container {
        flex-direction: column;
        text-align: center;
        color: #4d4d4f;
        display: flex;
        padding-left: 0;
        padding-right: 0;
      }

      .introduction-buttons {
        flex-direction: column;
        align-items: center;
        display: flex;

        .cui-button-primary-solid {
          background-color: black;
          border: black;
          color: white;
          height: 50px;
          width: 100%;
        }
      }
    }
  }
}