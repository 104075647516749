@import 'src/scss/mixins';

.bottom-sheet {
  &__button-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 16px;
    padding: 0 16px 16px;

    @include from($tablet) {
      justify-content: end;
      padding: 0 24px 24px 0;
    }

    button {
      min-width: 163px;
    }

    @include until($mobile) {
      button {
        width: 50%;
      }
    }
  }

  @include until($tablet) {
    .cui-bottom-sheet__header {
      justify-content: center;
      position: relative;
      padding-bottom: 8px;

      .cui-bottom-sheet__header__close-button {
        position: absolute;
        right: 16px;
      }
    }

    .cui-bottom-sheet__sheet {
      height: 100%;
      max-height: 667px;

      .cui-bottom-sheet__sheet__body {
        height: calc(100% - 52px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 8px;
        padding: 0;
      }
    }
  }
}
