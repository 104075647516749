.react-tooltip {
  &.tooltip__theme-dark {
    background-color: #000;
    color: #fff;
    border-radius: 8px;
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: 14px;
  }

  &.tooltip__theme-light {
    background-color: #fff;
    color: #000;
    border-radius: 8px;
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: 14px;
    box-shadow: 0px 0px 6px 0px rgba(35, 31, 32, 0.1),
      0px 2px 6px 0px rgba(35, 31, 32, 0.05);
  }

  .tooltip__copy {
    white-space: pre-wrap;
  }
}
