@import 'src/scss/variables';
@import 'src/scss/cui/variables';
@import 'src/scss/mixins';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &.primary {
    height: 40px;
    padding: 0 22px;
    background-color: $cookunity-white;
    border: 1px solid $cookunity-black-secondary;
    border-radius: 300px;

    @include font-build(
      $cui-font-family-tt-norms,
      $cui-font-sm,
      $cui-line-height-xl,
      $cui-font-bold
    );
  }

  &.secondary {
    height: 40px;
    padding: 0 22px;
    background-color: #ffc84e;
    border-radius: 300px;

    @include font-build(
      $cui-font-family-tt-norms,
      $cui-font-sm,
      $cui-line-height-xl,
      $cui-font-bold
    );
  }

  &__spinner-overlay {
    height: 100%;
    display: flex;
  }
}
