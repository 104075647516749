@import '../../../../../../scss/mixins';

.meals-grid {
  display: grid;
  // Each column will be px width + some equitable extra space if needed to complete the container width
  grid-template-columns: repeat(3, minmax(284px, 1fr));
  grid-gap: 1rem;
  row-gap: 2.625rem;
  grid-auto-rows: 430px;

  @include desktop {
    grid-template-columns: repeat(4, minmax(284px, 1fr));
  }

  &--home-new-layout {
    grid-auto-rows: 325px;

    @include desktop {
      grid-template-columns: repeat(5, minmax(220px, 1fr));
      grid-gap: 0.875rem;
    }
  }
}
