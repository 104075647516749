@import '../../../scss/cui/variables';
@import '../../../scss/mixins';

.card-v2 {
  scroll-snap-align: center;
  height: 100%;
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  cursor: pointer;
  border-radius: 8px;
  position: relative;
  background-color: $cui-white-color;
  border: 1px solid $cui-black-color-100;

  &--sold-out {
    opacity: 0.6;
  }

  .card-v2__chef__image-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__chef:not(.chef-avatar--extra-small) {
    padding: 12px 12px 0 12px;
  }

  &__img-wrapper {
    position: relative;
    height: 260px;
    min-height: 260px;

    &--new-home-layout {
      height: 170px;
      min-height: 170px;
    }
  }

  &__img-wrapper:before {
    content: '';
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      180deg,
      #f9f9f9 0.21%,
      rgba(249, 249, 249, 0) 10%
    );
    background-blend-mode: multiply, normal;
    z-index: 1;
  }

  &__img {
    height: 100%;
    overflow: hidden;
    background: url('../../../assets/img/image-placeholder.png');
    background-size: cover;
    background-position: center;
    img {
      transition: transform 0.5s ease;
    }
  }

  &__details {
    height: 100%;
    padding: 0 12px 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__characteristics {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 22px;

    &__rating {
      background-color: $cui-black-color-100;
      padding: 2px 4px;
      border: solid 1px $cui-black-color-150;
      border-radius: 4px;
    }
  }

  &__favorite {
    position: absolute;
    top: 16px;
    z-index: 1;
    right: 16px;
  }

  &__nutrition-button {
    position: absolute;
    top: 48px;
    z-index: 1;
    right: 16px;
  }
  &__nutrition-tooltip {
    z-index: 2;
    font-size: $cui-font-xs !important;
  }

  &__favorite--empty path {
    fill: $cui-black-color !important;
  }

  &__tags-wrapper {
    position: absolute;
    top: 16px;
    z-index: 1;
    left: 16px;
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    right: 40px;
  }

  &__tag {
    padding: 2px 4px;
    height: 20px;
    font-family: $cui-font-family-tt-norms;
    font-size: $cui-font-xs;
    line-height: $cui-line-height-xs;
  }

  &__tag-with-icon {
    svg {
      height: 12px;
      width: 12px;
      path {
        fill: $cui-white-color;
      }
    }
  }

  &__sold-out {
    background-color: $cui-black-color-350;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 9px;
    color: $cui-white-color;
    margin-top: auto;

    @include font-build(
      $cui-font-family-tt-norms,
      $cui-font-sm,
      $cui-line-height-xl,
      $cui-font-bold
    );
  }

  & .like_buttons {
    display: flex;
    justify-content: center;

    .button-like {
      width: 40px;
      height: 40px;
      background: #ffffff;
      box-shadow: 0 0 32px rgba(0, 0, 0, 0.04), 0 8px 16px rgba(0, 0, 0, 0.06);
      border-radius: 30px;
      border: none;
      padding: 8px;
      box-sizing: border-box;
      margin: 14px 12px 4px;
      cursor: pointer;
      outline: none;
      transition: box-shadow 0.2s 0s;

      &:hover {
        box-shadow: 0 0 48px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.2);
      }
    }
  }

  .see-details {
    @include font-build(
      $cui-font-family-tt-norms,
      $cui-font-xs,
      $cui-line-height-base,
      $cui-font-bold
    );
    text-decoration: underline;
  }

  .card-v2__actions-container {
    margin-top: auto;
    padding: 0 16px;
    .cui-meal-actions {
      margin-top: 0;
    }

    &--new-home-layout {
      .cui-meal-actions {
        .cui-meal-actions__button-add {
          @include font-build(
            $cui-font-family-tt-norms,
            $cui-font-xs,
            $cui-line-height-base,
            $cui-font-bold
          );
        }
      }
    }
  }

  .cui-meal-summary {
    margin-top: auto;
  }
}

.card-v2.extra-small {
  min-width: 195px;
  min-height: 268px;
  max-height: 268px;
  gap: 4px;

  .card-v2__details {
    padding: 0 16px 16px 16px;
    gap: 4px;
  }

  .card-v2__img-wrapper {
    height: 122px;
    min-height: 122px;
  }

  .card-v2__tags-wrapper {
    top: 12px;
    left: 12px;

    .card-v2__tag span {
      @include font-build(
        $cui-font-family-tt-norms,
        $cui-font-xxs,
        $cui-line-height-xs,
        $cui-font-bold
      );
    }
  }

  .card-v2__actions-container {
    padding: 0;
  }

  .card-v2__characteristics {
    margin-top: auto;
    overflow-x: scroll;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }

    .characteristics-l2__item {
      white-space: nowrap;
    }
  }
}

.card-v2:hover {
  box-shadow: 0px 0px 6px rgba(35, 31, 32, 0.1),
    0px 2px 6px rgba(35, 31, 32, 0.05);
  .card-v2__img img {
    transform: scale(1.2);
  }
}
