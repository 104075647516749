@import '../../scss/mixins';
@import '../../scss/variables';
@import '../../scss/cui/variables';

.cui-header {
  justify-content: space-between;

  @media screen and (max-width: $tablet) {
    padding: 0 16px;
    gap: 8px;
  }

  .cui-segment-icon-button {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cui-ally-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.active-challenge__header {
    flex-wrap: wrap;
    align-content: center;
    height: auto;
    padding: 16px 24px;

    .date-picker {
      height: auto;
    }

    .add-extra-delivery {
      flex-basis: 100%;
      padding: 0;
      justify-content: center;
    }

    .user-menu-button {
      position: absolute;
      z-index: 7;
    }
  }
}

.cui-header__nav-link {
  color: inherit;
}

.cui-header__extra-delivery {
  color: $cui-yellow-color-600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cui-header__extra-delivery__text {
  text-decoration: underline;

  @media screen and (min-width: $tablet) {
    text-decoration: none;
  }
}

.cui-header__title {
  font-family: $cui-font-family-tt-norms;
  font-size: $cui-font-xl;
  line-height: $cui-line-height-2xl;
  font-weight: 700;
  margin: auto;
  padding: 5px 0;
}
