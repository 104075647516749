@import '../../scss/variables';
@import '../../scss/_mixins';

.ReactModal__Overlay.ReactModal__Overlay--after-open.overlay:has(.challenge-modal) {
  z-index: 1000;
  display: flex;
  overflow: hidden;
  background-color: rgba(35, 31, 32, 0.7);
}

.cookunity__sliding_panel_wrapper
  .cookunity__sliding_panel:has(.cookunity__challenge_panel_header) {
  width: 100%;

  @include tablet {
    width: 506px;
  }

  .content {
    padding-bottom: 8px;
  }
}

.custom-header-mask {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.93) 0%,
    rgba(0, 0, 0, 0.9353) 28.3%,
    rgba(0, 0, 0, 0.68355) 62.01%,
    rgba(0, 0, 0, 0) 100.19%
  );
  border-radius: 0px 0px 20px 20px;
}

.challenge-promotion {
  margin: 0 35px 28px 35px;
  z-index: 2;
  width: 100%;
  height: 259px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: white;

  .title {
    font-family: TT Norms Pro;
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0px;
    text-align: left;
    margin: 0;
  }

  .subtitle {
    font-weight: 450;
    font-size: 13px;
    line-height: 20px;
    margin-top: 4px;
    margin-bottom: 0;
  }

  #five-milestone-challenge-tooltip {
    max-width: 400px;
    background-color: white;
    color: $cookunity-black-secondary;
    font-weight: 450;
    font-size: 13px;
    line-height: 20px;
  }
}

.challenge-promotion-highligth {
  color: #ffb81c;
}

.cookunity__challenge_panel {
  padding: 20px 14px 0 14px;

  &_header {
    display: flex;
    position: relative;
    box-sizing: border-box;
    color: white;
    color: $cookunity-black;
    background: url('../../assets/img/challengeBanner/food1.png');
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    flex-shrink: 0;
    border-radius: 0px 0px 20px 20px;

    &.completed {
      background-image: url('../../assets/img/challengeBanner/food2.png');
    }

    .challenge-promotion-button {
      margin-top: 12px;
      width: 100%;
    }
    .container_progress_bar {
      margin-top: 12px;
      color: $cookunity-black-secondary;
    }
  }
}

.challenge-modal.content.large_size {
  padding-top: 44px;
  align-self: self-end;

  @include tablet {
    align-self: center;
    padding: 65px;
    padding-bottom: 28px;
  }

  @include mobile {
    border-radius: 24px 24px 0px 0px !important;
    animation: slideFromBottom 0.5s forwards;
  }
}

.challenge-modal--title {
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  max-width: 570px;
  margin: auto;
  margin-bottom: 32px;

  @include tablet {
    margin-bottom: 45px;
  }
}

.challenge-modal--img.cui-image {
  width: auto;
  height: 486px;
  margin: auto;

  @include tablet {
    height: 355px;
  }
}

.challenge-modal--btn {
  margin-top: 32px;

  button {
    width: 100%;
  }

  @include tablet {
    display: flex;
    justify-content: flex-end;
    width: auto;
  }
}

@keyframes slideFromBottom {
  from {
    bottom: -100%;
  }
  to {
    bottom: 0;
  }
}
