@import 'src/scss/variables';
@import 'src/scss/cui/variables';
@import 'src/scss/mixins';

.meal-rating-card-detail {
  width: 100%;
  height: 152px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 0;
  padding: 0;
  border-radius: 8px;

  &__image {
    width: 119px;
    height: 152px;
    object-fit: cover;
    border-radius: 8px 0px 0px 8px;
  }

  &__details {
    width: calc(100% - 119px);
    height: 152px;
    padding: 8px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;

    @include mobile {
      padding-right: 8px;
      padding-top: 24px;
    }
  }

  &__details-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 8px;
  }

  &__details-title-and-ratings {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 8px;
  }

  &__details-title {
    text-align: left !important;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    text-overflow: ellipsis;
    margin-top: 8px;
  }

  &__ratings {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 8px;

    &.clickable svg {
      cursor: pointer;
    }
  }

  &__icons {
    display: flex;
    flex-direction: column;
    gap: 12px;

    svg {
      cursor: pointer;
    }
  }

  &__details-bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__rated-meal-tag.cui-tag-green {
    width: 52px;
    padding: 2px 4px 2px 2px;
    gap: 0;
    background-color: $cookunity-secondary-green;
    border-style: none;
    border-radius: 2px;
  }
}
