@import '../../../../scss/mixins';
@import '../../../../scss/cui/variables';

.change-plan-success-modal {
  &__spinner {
    margin: auto;
    display: flex;
  }
  &--container {
    max-width: 490px;
    margin: auto;

    .cui-button {
      width: 100%;
      margin: auto;
      margin-top: 24px;

      @include tablet {
        width: auto;
      }
    }
  }

  .title {
    color: $cui-darkgreen-color;
    text-align: center;
    font-family: 'TT Norms Pro';
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    margin-bottom: 38px;

    &__action {
      @include tablet {
        display: block;
      }
    }

    &:has(.title__action) {
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;

      @include tablet {
        max-width: 490px;
      }
    }

    @include tablet {
      font-family: Rossanova;
      font-size: 36px;
      line-height: 44px;
      margin-bottom: 43px;
    }
  }

  .description {
    max-width: 339px;
    margin: auto;

    &--text {
      font-family: 'TT Norms Pro';
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 8px;

      @include tablet {
        text-align: center;
      }
    }
  }

  &.cui-bottom-sheet .cui-bottom-sheet__sheet > .cui-bottom-sheet__header {
    border-bottom: 0;
    padding-bottom: 0;
  }

  .cui-bottom-sheet__sheet__body {
    padding-top: 0;

    .cui-icon {
      color: $cui-darkgreen-color;
      height: 65px;
      width: 65px;
      margin: auto;

      svg {
        height: 100%;
      }
    }
  }
}
