@import 'src/scss/mixins';
@import 'src/scss/cui/variables';

.preferences-diets {
  border-radius: 16px;
  padding: 16px;
  box-shadow: 0 2px 6px 0 rgba(35, 31, 32, 0.05),
    0 0 6px 0 rgba(35, 31, 32, 0.1);
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;

  @include mobile {
    padding: 24px;
    height: auto;
  }

  &__list {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    justify-content: center;
    @include mobile {
      flex-direction: column;
    }
  }

  &__item {
    cursor: pointer;
    color: $cui-black-color;
    @include font-build(
      $cui-font-family-tt-norms,
      $cui-font-sm,
      $cui-line-height-xl,
      $cui-font-bold
    );
    min-width: 94px;
    max-width: 94px;
    height: 100px;
    border-radius: 16px;
    border: 1px solid $cui-black-color-600;
    display: flex;
    padding: 8px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    align-self: stretch;
    text-align: center;

    svg {
      width: 32px;
      height: 32px;
    }

    @include mobile {
      min-width: 100%;
      flex-direction: row;
      padding: 8px 24px;
      justify-content: flex-start;
      text-align: left;
      border-radius: 8px;
    }

    &-description {
      color: $cui-black-color-400;
      @include font-build(
        $cui-font-family-tt-norms,
        $cui-font-xxs,
        $cui-line-height-xs,
        $cui-font-normal
      );
    }

    &.selected {
      border: 4px solid $cui-wine-color-700;
      background: $cui-wine-color-500;
      div {
        color: $cui-white-color;
      }
      svg path {
        fill: $cui-white-color;
      }
    }
  }

  &__item:hover {
    background-color: $cui-black-color-150;
  }

  &__item.selected:hover {
    background-color: $cui-wine-color-700;
  }
}
