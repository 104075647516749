.impersonation-panel {
  padding: 10px;
  background-color: #fff;
  width: 500px;
  height: 180px;
  position: fixed;
  top: 150px;
  left: 20px;
  z-index: 9999;
  border: solid 1px #ccc;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  .close-panel {
    font-weight: 700;
    align-self: flex-end;
    cursor: pointer;
  }
  .impersonate {
    font-weight: 600;
  }
}

.minimized {
    cursor: pointer;
    padding: 10px;
    background-color: #FFC84E;
    color: black;
    width: 50px;
    height: 50px;
    position: fixed;
    top: 150px;
    left: 20px;
    z-index: 9999;
    border: solid 1px #ccc;
    border-radius: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    font-weight: 600;
  }