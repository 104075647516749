@import 'src/scss/mixins';
@import 'src/scss/cui/variables';

.preferences {
  width: 100%;

  &__container {
    padding: 40px 0;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: $cui-white-color;

    @include mobile {
      min-width: 100%;
      padding: 0;
    }

    @include from($tablet) {
      .meal-card-slider__desktop {
        padding: 0 8px;
      }
    }
  }

  &__header {
    background-color: $cui-white-color;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @include until($widescreen) {
      padding: 0 16px;
    }

    @include mobile {
      width: 100%;
      position: sticky;
      top: 0;
      z-index: 3;
      gap: 4px;
      margin-bottom: -16px;
      padding-top: 16px;
      padding-bottom: 16px;

      .hidden-mobile {
        display: none;
      }
    }
  }

  &__progress-bar-wrapper {
    @include until($widescreen) {
      padding: 0 16px;
    }
  }

  &__back {
    margin-left: -60px;
    margin-top: 14px;
    position: absolute;
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    @include font-build(
      $cui-font-family-tt-norms,
      $cui-font-xs,
      $cui-line-height-base,
      $cui-font-bold
    );
    img {
      width: 14px;
    }
    @include until(1300px) {
      position: relative;
      margin: 0;
    }
  }

  &__title {
    margin: 0;
    color: $cui-black-color;
    @include font-build(
      $cui-font-family-rossanova,
      $cui-font-4xl,
      $cui-line-height-5xl,
      $cui-font-bold
    );

    @include mobile {
      @include font-build(
        $cui-font-family-tt-norms,
        $cui-font-3xl,
        $cui-line-height-4xl,
        $cui-font-bold
      );
    }
  }

  &__subtitle {
    margin-top: -8px;
    @include font-build(
      $cui-font-family-tt-norms,
      $cui-font-sm,
      $cui-line-height-xl,
      $cui-font-normal
    );
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 32px;

    @include until($tablet) {
      > div {
        width: 100%;
      }
    }

    @include from($desktop) {
      flex-direction: row;
    }

    @include until($desktop - 1px) {
      flex-direction: column;
    }

    &__autopilot {
      height: min-content;
      background-color: #ededed;
      border-radius: 16px;
      order: 2;

      @include from($desktop) {
        width: 483px;
        order: 1;
      }

      @include until($tablet) {
      }
    }

    &__profiles {
      display: flex;
      flex-direction: column;
      gap: 24px;
      order: 1;

      @include from($desktop) {
        width: calc(100% - 483px - 32px);
        order: 2;
      }
    }

    @include until($widescreen) {
      padding: 0 16px;
    }

    @include mobile {
      flex-wrap: wrap;
    }

    &:not(.with-autopilot-panel) {
      .preferences__body__profiles {
        width: 100%;
      }

      @include from($desktop) {
        .preferences__dropdowns-row > div {
          max-width: calc((100% - 24px * 3) / 4);

          .preferences-dropdown {
            height: 148px;
          }
        }
      }

      @include tablet-only {
        .preferences__dropdowns-row > div {
          width: 100%;
          max-width: calc(50% - 24px / 2);
        }
      }
    }

    &.with-autopilot-panel {
      .preferences__dropdowns-row > div {
        min-width: calc((100% - 24px) / 2);
      }
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;

    > div {
      flex: 1;

      @include mobile {
        max-height: 388px;
      }
    }

    @include mobile {
      flex-direction: column;
      padding: 0;
    }
  }

  &__dropdowns-row {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;

    @include mobile {
      flex-direction: column;
      padding: 0;
    }

    > div {
      flex: 1 1;

      @include until($desktop) {
        min-width: auto;
        flex: auto;
      }
    }
  }

  &-loading__form {
    @include shimmer();
    height: 200px;
    @include mobile {
      margin: 0 16px;
    }
  }

  &__delete-profile {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    max-width: max-content;
    @include font-build(
      $cui-font-family-tt-norms,
      $cui-font-sm,
      $cui-line-height-xl,
      $cui-font-bold
    );
  }

  @include mobile {
    .hidden-mobile {
      display: none;
    }
  }

  @include tablet {
    .only-mobile {
      display: none;
    }
  }

  @include desktop {
    .only-mobile {
      display: none;
    }
  }
}

.preferences-footer {
  position: sticky;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 96px;
  background-color: $cui-white-color;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-shadow: 0 2px 6px 0 rgba(35, 31, 32, 0.05),
    0 0 6px 0 rgba(35, 31, 32, 0.1);
  padding: 16px 24px;
  z-index: 3;

  @include mobile {
    height: 80px;
    button {
      width: 100%;
    }
  }
}
