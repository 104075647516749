@import '../../scss/mixins';

.simple-carousel {
  display: flex;
  flex-direction: row;
  gap: 14px;
  overflow: auto;
  scroll-snap-type: x mandatory;

  @include desktop {
    justify-content: space-between;
  }
}

.simple-carousel__item {
  scroll-snap-align: start;
}
