@import '../../scss/cui/variables';
@import '../../scss/variables';

.challenge-resume {
  margin: 16px;
  background-color: $cui-black-color-3;
  color: $cui-white-color;
  border-radius: 8px;
  border: 1px solid $cookunity-gray-3;
  padding: 20px 13px 20px 15px;
  display: flex;
  align-items: center;
  gap: 12px;
  font-family: 'TT Norms Pro';

  &--progress {
    border-radius: 50%;
    background-color: $cui-white-color;
    color: $cookunity-black-secondary;
    text-align: center;
    width: 64px;
    height: 64px;
    line-height: 12px;
    font-weight: 700;
    display: flex;
    align-items: center;

    flex-shrink: 0;
    position: relative;

    span {
      font-size: 10px;
      font-weight: 450;
    }

    svg {
      position: absolute;
      right: -1px;
      top: 0;
    }
  }

  &--title {
    color: #ffb81c;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }

  &--description {
    font-size: 12px;
    line-height: 16px;
  }

  &--cta {
    &,
    &:hover,
    &:focus {
      color: $cui-white-color;
      background-color: transparent;
      padding: 0 0.5px;
      border: none;
      border-bottom: 1.5px solid $cui-white-color;
      border-radius: 0;
      padding-top: 7px;
    }
  }
}
