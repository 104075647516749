@import '../../../../scss/mixins';
@import '../../../../scss/variables';
@import '../../../../scss/cui/variables';

.cookunity__my_plan_section.upgrade_to_standard_section {
  .upgrade_to_standard_title {
    display: flex;
    align-items: center;
    gap: 5px;

    button {
      background-color: #97225a;
      border-color: #97225a;
      border-radius: 5px;
      color: $cookunity-white-color;
      padding: 5px 10px;
    }
  }

  .upgrade_to_standard_subtitle {
    p {
      margin: 0;
      padding: 0;
      span {
        font-size: 1em !important;
        font-weight: unset !important;
      }
    }
    .advice {
      font-weight: bold !important;
    }

    .note {
      font-size: 0.9em !important;
    }
  }
}

.information-modal__content,
.confirmation-modal__content {
  .title {
    color: $cookunity-black;
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 25px;
    padding-top: 25px;
    text-align: center;
  }

  .content {
    color: $cookunity-black;
    font-size: 1em;
    margin-bottom: 25px;
    text-align: left;
    padding: 15px;
    white-space: pre-wrap;
    line-height: 1.3em;
  }
  .content p {
    margin-top: 2px;
    margin-bottom: 35px;
  }

  .action-buttons {
    display: flex;
    gap: 10px;
    margin-top: 32px;
    flex-direction: column;
    width: 100%;
    @include tablet {
      justify-content: center;
      gap: 25px;
      flex-direction: row;
    }
  }
}
