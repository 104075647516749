@import 'src/scss/variables';
@import 'src/scss/cui/variables';
@import 'src/scss/mixins';

.text {
  text-align: center;
  margin: 0;

  &.h1 {
    @include font-build(
      $cui-font-family-tt-norms,
      $cui-font-3xl,
      $cui-line-height-4xl,
      $cui-font-bold
    );
  }

  &.h3 {
    @include font-build(
      $cui-font-family-tt-norms,
      $cui-font-xl,
      $cui-line-height-2xl,
      $cui-font-bold
    );
  }

  &.h5 {
    @include font-build(
      $cui-font-family-tt-norms,
      $cui-font-base,
      22px,
      $cui-font-bold
    );
  }

  &.medium-regular {
    @include font-build(
      $cui-font-family-tt-norms,
      $cui-font-base,
      $cui-line-height-2xl,
      $cui-font-normal
    );
    color: $cui-gray-color;
  }

  &.medium-bold {
    @include font-build(
      $cui-font-family-tt-norms,
      $cui-font-base,
      $cui-line-height-2xl,
      $cui-font-bold
    );
    color: $cui-gray-color;
  }

  &.small-bold {
    @include font-build(
      $cui-font-family-tt-norms,
      $cui-font-sm,
      $cui-line-height-xl,
      $cui-font-bold
    );
  }

  &.xs-bold {
    @include font-build(
      $cui-font-family-tt-norms,
      $cui-font-xs,
      $cui-line-height-base,
      $cui-font-bold
    );
    color: $cui-gray-color;
  }

  &.xxs-bold {
    @include font-build(
      $cui-font-family-tt-norms,
      $cui-font-xxs,
      $cui-line-height-xs,
      $cui-font-bold
    );
  }
}
