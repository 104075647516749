@import '../../scss/mixins';
@import '../../scss/variables';
@import '../../scss/cui/variables';

.container_progress_bar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;

  .content_progres_bar {
    background-color: #ffe3a4;
    border-radius: 20px;
    overflow: hidden;
    height: 33px;
    width: 100%;
    display: flex;
    flex-direction: row;

    .progress-bar {
      background: #ffb81c;
      border-radius: 20px;
      height: 100%;
      animation: progress 1000ms ease-in 1;
      display: flex;
      justify-content: flex-end;
      @keyframes progress {
        from {
          width: 0px;
        }
      }

      &--icon {
        margin: auto 8px auto 8px;
        height: 100%;

        svg {
          height: 100%;
        }
      }
    }
  }
}
