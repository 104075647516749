@import '../../scss/mixins';
@import '../../scss/variables';

.resurrection-landing {
  font-family: $cui-font-family-tt-norms;

  .outline-tabs {
    display: none;
    @include desktop {
      margin-bottom: 25px;
      display: block;
      text-transform: uppercase;
    }
  }

  .title {
    margin-top: 0;
    margin-bottom: 10px;
    font-family: $cui-font-family-tt-norms;
    font-size: 24px;
    font-weight: 700;

    @include desktop {
      font-family: $cui-font-family-rossanova;
      font-size: 2.25rem;
    }
  }

  .subtitle {
    line-height: 1.25rem;
    font-size: 0.875rem;
    margin-bottom: 10px;
  }

  .anchor {
    font-size: 0.875rem;
    display: inline-block;
    margin-left: 5px;
    color: $cookunity-primary-wine;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    @include desktop {
      display: block;
      margin-left: 0;
    }
  }

  .headline {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;

    @include desktop {
      flex-direction: row;
      align-items: center;
      margin: 30px 0;
    }

    .titles {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .resume {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px 0;

      @include desktop {
        border-radius: 8px;
        border: 1px solid #eee;
        background: #f5f5f5;
        padding: 24px;
        margin: 0;
      }

      .row {
        display: flex;
        align-items: center;

        .cell {
          display: flex;
          align-items: center;
        }

        .label {
          margin-right: 10px;
        }

        .bold {
          font-weight: 700;
        }
      }
    }
  }

  .message {
    color: #000;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 20px auto;
    padding: 20px 0;

    @include desktop {
      padding: 60px 0;
    }

    .title {
      font-family: $cui-font-family-tt-norms;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 30px;

      @include desktop {
        font-size: 28px;
        font-weight: 500;
        line-height: 36px;
      }
    }
  }
}
