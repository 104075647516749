@import '../../../../scss/cui/variables';
@import '../../../../scss/mixins';

.characteristics-l2 {
	display: flex;
	flex-direction: row;
	gap: 4px;
	align-items: center;

	&__item {
		display: flex;
		align-items: center;
		gap: 4px;
		color: $cui-black-color;
		&--medium {
			@include font-build(
				$cui-font-family-tt-norms,
				$cui-font-xs,
				$cui-line-height-base,
				$cui-font-bold
			);
			svg {
				height: 10px;
				width: 10px;
				margin-top: -2px;
			}
		}
		&--small {
			@include font-build(
				$cui-font-family-tt-norms,
				$cui-font-xs,
				$cui-line-height-base,
				$cui-font-bold
			);
			svg {
				height: 12px;
				width: 12px;
			}
		}
	}

	&__separator {
		background-color: $cui-black-color;
		width: 3px;
		height: 3px;
		border-radius: 20px;
	}
}