@import './../UnityPassCommon';

.unity-pass {
  .confirmation-container {
    flex-direction: column;
    position: relative;
    padding: 20px;
    display: flex;

    .title-container {
      flex-direction: column;
      padding: 15px;
      display: flex;
      justify-content: center;
      align-items: center;

      .title {
        font-family: $cui-font-family-rossanova;
        text-align: center;
        line-height: 35px;
        font-size: 25px;
        margin-bottom: 0px;

        @include tablet {
          font-size: 30px;
          margin-bottom: 0px;
        }
      }

      .secondary-title {
        color: $cui-gray-color;
      }

      .subtitle {
        font-family: TT Norms Pro;
        text-align: center;
        line-height: 24px;
        color: $cui-gray-color;
        font-weight: 450;
        font-size: 14px;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        @include tablet {
          font-size: 16px;
          padding-bottom: 10px;
          margin-top: 20px;
          max-width: 500px;
        }
      }
    }
    .confirmation-buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px;

      @include tablet {
        padding-bottom: 20px;
        padding-top: 20px;
      }

      .cui-button-primary-solid {
        background-color: $cui-black-color-0;
        border: $cui-black-color-0;
        color: $cui-white-color;
        height: 50px;
        width: 300px;
        @include tablet {
          width: 100%;
        }
      }
    }
  }
}
