@import '../../../../scss/cui/variables';
@import '../../../../scss/mixins';

.chef-avatar {
  display: flex;
  flex-direction: row;
  align-items: center;
  &--extra-small,
  &--small {
    gap: 4px;
  }
  &--medium {
    gap: 8px;
    cursor: pointer;
  }

  &__img {
    border-radius: 100px;
    object-fit: cover;
    &--extra-small {
      width: 16px;
      height: 16px;
    }
    &--small {
      width: 20px;
      height: 20px;
    }
    &--medium {
      width: 30px;
      height: 30px;
    }
    &--large {
      width: 64px;
      height: 64px;
      @include mobile {
        width: 40px;
        height: 40px;
      }
    }
  }

  &__name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $cui-black-color;
    &--extra-small,
    &--small {
      @include font-build(
        $cui-font-family-tt-norms,
        $cui-font-xxs,
        $cui-line-height-xs,
        $cui-font-normal
      );
    }
    &--medium {
      @include font-build(
        $cui-font-family-tt-norms,
        $cui-font-sm,
        $cui-line-height-xl,
        $cui-font-bold
      );
    }
  }
}
