@import './../UnityPassCommon';

.unity-pass {
  .checkout-container {
    flex-direction: column;
    position: relative;
    display: flex;
    padding: 20px;

    .spinner-overlay {
      background: rgba(255, 255, 255, 0.8);
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      position: absolute;
      display: flex;
      height: 100%;
      z-index: 999;
      width: 100%;
      left: 0;
      top: 0;
    }

    .title {
      font-family: Rossanova;
      text-align: center;
      line-height: 44px;
      font-size: 30px;
    }
  }

  .summary-container {
    border: 1px solid rgba(79, 79, 77, 0.2);
    flex-direction: column;
    margin-inline: 10px;
    border-radius: 8px;
    margin-top: 20px;
    display: flex;
    padding: 25px;

    @include tablet {
      margin-inline: 30px;
    }

    .title {
      font-family: TT Norms Pro;
      align-items: flex-start;
      margin-bottom: 20px;
      line-height: 24px;
      font-weight: 700;
      text-align: left;
      font-size: 20px;
      display: flex;
    }

    .detail {
      justify-content: space-between;
      flex-direction: row;
      margin-bottom: 10px;
      display: flex;
    }

    .chef-discount {
      justify-content: space-between;
      flex-direction: row;
      margin-bottom: 10px;
      display: flex;
      padding-top: 20px;
      font-weight: 600;
      color: $cui-darkgreen-color;
    }

    .summary-description {
      font-family: TT Norms Pro;
      align-items: flex-start;
      flex-direction: column;
      text-align: justify;
      position: relative;
      margin-left: 20px;
      line-height: 20px;
      text-align: left;
      font-weight: 450;
      font-size: 14px;
      display: flex;
      color: $cui-gray-color;

      .first {
        margin-bottom: 5px;
        &::before {
          position: absolute;
          content: '•';
          left: -1em;
        }
      }

      .second {
        &::before {
          position: absolute;
          content: '•';
          left: -1em;
        }
      }
    }

    .dotted-line {
      border: none;
      border-top: 1px dotted #dddddd;
      margin: 20px 0;
    }

    .summary-footer {
      justify-content: space-between;
      flex-direction: row;
      display: flex;
      font-family: TT Norms Pro;
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      text-align: left;
    }
  }

  .terms {
    font-family: TT Norms Pro;
    font-size: 14px;
    font-weight: 450;
    line-height: 20px;
    text-align: left;
    margin-inline: 10px;
    margin-top: 20px;
    color: $cui-gray-color;

    @include tablet {
      margin-inline: 30px;
    }

    a {
      color: $cui-gray-color;
      font-weight: bold;
      text-decoration: underline;
    }
  }

  .error {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border: 2px solid #ee5744;
    border-radius: 4px;
    margin: 30px;
    margin-top: 15px;
    margin-bottom: -12px;
    padding: 15px;
    color: #ee5744;

    .alert-error {
      height: 16px;
      width: 16px;
      margin-right: 10px;

      path {
        fill: #ee5744;
      }
    }
  }
}
