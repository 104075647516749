@import '../_mixins';

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.quarter-width {
  width: 25%;
}

.hidden {
  display: none;
}

.only-mobile {
  display: none;

  @include mobile {
    display: initial;
  }
}

.only-desktop {
  @include mobile {
    display: none !important;
  }
}

.text-center {
  text-align: center;
}

.d-contents {
  display: contents;
}

.text-right {
  text-align: right;
}

.anchor {
  color: $cui-wine-color-500;
  text-decoration-line: underline;
  font-weight: bold;
}

.basic-card {
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05),
    0px 0px 2px 0px rgba(35, 31, 32, 0.1);
  border-radius: 8px;
}
