.outline-tabs {
  display: flex;
  gap: 10px;

  .outline-tabs__tab {
    color: #a8a8a8;
    font-weight: bold;
    border-bottom: 2px solid #a8a8a8;
    padding-bottom: 3px;

    font-family: $cui-font-family-tt-norms;
    font-size: 12px;

    &.active {
      color: black;
      border-bottom: 2px solid black;
    }
  }
}
