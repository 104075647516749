@import '../../../../scss/cui/variables';
@import '../../../../scss/mixins';

.rebranding {
  .cookunity__delete_partner_modal {
    .title {
      padding-top: 0.75rem;
      white-space: nowrap;

      @include font-build(
        $cui-font-family-tt-norms,
        $cui-font-xl,
        $cui-line-height-2xl,
        $cui-font-bold
      );

      @include mobile {
        text-align: center;
        padding-top: 0;
        white-space: normal;
      }
    }

    .button_row {
      display: flex;
      gap: 16px;
      button {
        @include mobile {
          margin-top: 1rem;
        }
      }
    }
  }
}
