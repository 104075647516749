@import './../UnityPassCommon';

.unity-pass-benefits-container {
  padding: 20px;

  .spinner-overlay {
    background: rgba(255, 255, 255, 0.8);
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    position: absolute;
    display: flex;
    height: 100%;
    z-index: 999;
    width: 100%;
    left: 0;
    top: 0;
  }

  .benefits-title-container {
    flex-direction: column;
    align-items: center;
    display: flex;

    @include tablet {
    }

    .title {
      font-family: $cui-font-family-rossanova;
      text-align: center;
      line-height: 35px;
      font-size: 25px;
      margin-bottom: 0px;

      @include tablet {
        font-size: 30px;
        margin-bottom: 0px;
      }
    }

    .secondary-title {
      color: $cui-gray-color;
    }

    .subtitle {
      font-family: $cui-font-family-tt-norms;
      text-align: center;
      line-height: 24px;
      color: $cui-gray-color;
      font-weight: 450;
      font-size: 14px;
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
      @include tablet {
        font-size: 16px;
        padding-bottom: 10px;
        margin-top: 20px;
        max-width: 500px;
      }
    }
  }
  .benefits-description-container {
    background-color: #f8f8f8;
    flex-direction: column;
    border-radius: 10px;
    color: $cui-gray-color;
    display: flex;
    padding: 15px;
    padding-top: 15px;

    @include tablet {
      margin-inline: 30px;
      padding: 35px;
    }

    .benefit-container-title {
      font-family: $cui-font-family-tt-norms;
      text-align: center;
      font-weight: 700;
      font-size: 16px;
      color: $cui-black-color-0;
      padding-top: 13px;
    }
    .benefit-container {
      justify-content: flex-start;
      flex-direction: row;
      margin-top: 30px;
      margin-bottom: 8px;
      display: flex;
      .image {
        justify-content: center;
        align-items: center;
        display: flex;
        background-color: $cui-white-color;
        border-radius: 50%;
        height: 50px;
      }
      .details {
        padding: 20px;
        flex: 1;
      }
      .benefit-details {
        align-items: flex-start;
        flex-direction: column;
        margin-left: 18px;
        display: flex;
        .benefit-title {
          font-family: $cui-font-family-tt-norms;
          line-height: 28px;
          font-weight: 700;
          text-align: left;
          font-size: 16px;
          color: $cui-black-color-0;

          @include tablet {
            font-size: 19px;
          }
        }
        .benefit-description {
          font-family: $cui-font-family-tt-norms;
          line-height: 20px;
          text-align: left;
          font-weight: 450;
          padding-right: 10px;
          font-size: 16px;
          color: $cui-gray-color;

          @include tablet {
            line-height: 24px;
            font-size: 19px;
            padding-right: 25px;
          }
        }
      }
    }
  }
  .footer-container {
    flex-direction: column;
    margin-top: 15px;
    gap: 5px;
    display: flex;
    width: 100%;
    .footer-header {
      font-family: TT Norms Pro;
      text-align: center;
      line-height: 22px;
      font-weight: 700;
      font-size: 14px;

      @include tablet {
        font-size: 16px;
        margin-top: 10px;
      }
    }

    .footer-subheader {
      font-family: TT Norms Pro;
      text-align: center;
      line-height: 22px;
      color: #70707b;
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 18px;
      @include tablet {
        font-size: 16px;
        margin-top: 10px;
      }
    }
    .rejoin {
      font-family: TT Norms Pro;
      font-size: 16px;
      font-weight: 450;
      line-height: 24px;
      text-align: center;
      padding: 20px;
    }
    .cancel-terms {
      font-family: TT Norms Pro;
      font-size: 14px;
      font-weight: 450;
      line-height: 20px;
      text-align: left;
      margin-inline: 10px;
      margin-top: 10px;
      margin-bottom: 25px;
      color: $cui-gray-color;
      text-align: center;
      @include tablet {
        margin-inline: 40px;
      }

      a {
        color: $cui-gray-color;
        font-weight: bold;
        text-decoration: underline;
      }
    }
    .cancel {
      text-decoration: underline;
      flex-direction: column;
      padding-bottom: 25px;
      align-items: center;
      color: #97225a;
      font-weight: 700;
      font-size: 14px;
      cursor: pointer;
      display: flex;
    }
  }
}
