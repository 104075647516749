@import '../../../../scss/cui/variables';
@import '../../../../scss/mixins';

.characteristics-l1 {
  display: flex;
  flex-direction: row;
  gap: 4px;

  &__item {
    display: flex;
    width: 20px;
    height: 20px;
    padding: 2px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;

    svg path {
      fill: $cui-white-color;
    }
    svg {
      max-width: 16px;
      max-height: 16px;
    }
    @include mobile {
      width: 16px;
      height: 16px;
      svg {
        max-width: 12px;
        max-height: 12px;
      }
    }
  }

  &__tooltip {
    background-color: $cui-black-color-400 !important;
    color: $cui-white-color;
    font-size: 12px !important;
    @include font-build(
      $cui-font-family-tt-norms,
      $cui-font-xs,
      $cui-line-height-base,
      $cui-font-normal
    );
    max-width: 127px;
    padding: 10px !important;
    z-index: 2;
  }
}
