@import '../../scss/mixins';
@import '../../scss/variables';

.membership-modal {
  &.content {
    padding: 0px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    max-width: 666px;
  }

  .cui-bottom-sheet__overlay {
    .cui-bottom-sheet__sheet__body {
      padding: 0px;
      max-height: 95vh;
      overflow-y: auto;
    }
  }

  .cui-bottom-sheet__header {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 0px;
    border-bottom: none;
  }
}