@import './../UnityPassCommon';

.unity-pass {
  .unity-pass-banner-with-image {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background: #c7b273;
    font-family: $cui-font-family-tt-norms;
    height: 100px;
    padding-bottom: 20px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 180px;
      object-fit: cover;
    }
  }

  .unity-pass-image-container {
    height: 300px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include tablet {
        height: 300px;
      }
    }
  }

  .introduction-container {
    padding: 20px;

    .title-container {
      flex-direction: column;
      display: flex;

      .title {
        font-family: $cui-font-family-rossanova;
        text-align: center;
        line-height: 42px;
        font-size: 25px;

        @include tablet {
          font-size: 30px;
          margin-bottom: 10px;
        }
      }

      .subtitle {
        font-family: $cui-font-family-rossanova;
        text-align: center;
        line-height: 30px;
        color: $cui-gray-color;
        font-size: 25px;

        @include tablet {
          font-size: 30px;
          margin-bottom: 20px;
        }
      }
    }

    .description-container {
      font-family: 'TT Norms Pro';
      text-align: center;
      line-height: 24px;
      color: $cui-gray-color;
      font-size: 16px;
      padding: 10px;

      @include tablet {
        padding-left: 90px;
        padding-right: 90px;
        padding-bottom: 0;
      }

      .old-cost {
        text-decoration: line-through;
        color: $cui-gray-color;
        font-size: 16px;
        font-weight: 600;
        padding-right: 5px;
      }
    }

    .action-buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 10px;
      @include tablet {
        padding-top: 20px;
      }

      .cui-button-primary-solid {
        background-color: $cui-black-color-0;
        border: $cui-black-color-0;
        color: $cui-white-color;
        height: 50px;
        width: 100%;
        @include tablet {
          width: 300px;
        }
      }
    }
  }
}
