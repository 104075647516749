@import '../../../../scss/mixins';
@import '../../../../scss/variables';
@import '../../../../scss/cui/variables';

.cookunity__setting-section-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-top: 1.75rem;

  @include mobile {
    flex-direction: column;
    margin-top: 1.875rem;
  }

  &.loading {
    cursor: default;
    color: transparent;
    user-select: none;

    .cookunity__setting-section-text-container {
      width: 50%;
      height: 64px;

      @include mobile {
        width: 100%;
        height: 108px;
      }
    }

    .cookunity__plan-paused-section-title {
      @include shimmer();
      background-color: #fafafa;
      width: 40%;
      margin-bottom: 2px;
    }

    .cookunity__plan-paused-section-info {
      & > * {
        @include shimmer();
        background-color: #fafafa;
        width: 100%;
        margin-bottom: 2px;
      }
    }
  }

  .cookunity__setting-section-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .cookunity__setting-section-title {
      color: $cui-black-color;

      @include font-build(
        $cui-font-family-tt-norms,
        $cui-font-base,
        $cui-line-height-2xl,
        $cui-font-normal
      );

      @include mobile {
        margin-bottom: 1.5rem;
        font-size: $cui-font-sm;
        line-height: $cui-line-height-xl;
      }
    }

    .cookunity__setting-section-subtitle {
      color: $cui-black-color;

      @include font-build(
        $cui-font-family-tt-norms,
        $cui-font-base,
        $cui-line-height-2xl,
        $cui-font-normal
      );

      @include mobile {
        font-size: $cui-font-sm;
        line-height: $cui-line-height-xl;
      }
    }

    .cookunity__plan-paused-section-title {
      color: $cui-black-color;

      @include font-build(
        $cui-font-family-tt-norms,
        $cui-font-base,
        $cui-line-height-2xl,
        $cui-font-bold
      );

      @include mobile {
        margin-bottom: 4px;
        font-size: $cui-font-base;
        line-height: $cui-line-height-xl;
      }
    }

    .cookunity__plan-paused-section-info {
      color: $cui-black-color;
      margin: 0;
      padding-inline-start: 22px;

      li::marker {
        font-size: $cui-font-xs;
      }

      @include font-build(
        $cui-font-family-tt-norms,
        $cui-font-sm,
        $cui-line-height-xl,
        $cui-font-normal
      );

      @include mobile {
        font-size: $cui-font-sm;
        line-height: $cui-line-height-xl;
      }

      &.loading {
        @include shimmer();
        background-color: #fafafa;
        // width: 20%;
      }
    }

    .cookunity__open-feedback-after-pausing-link {
      margin-top: 16px;
      color: $cui-wine-color-500;
      cursor: pointer;
      font-family: 'TT Norms Pro';
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
    }

    span.link {
      font-family: $cui-font-family-tt-norms;
      font-weight: $cui-font-bold;
      color: $cui-wine-color-500;
      text-decoration-line: underline;
      cursor: pointer;

      &.check-calendar-pause {
        font-size: $cui-font-sm;
        margin-top: 24px;

        @include desktop {
          margin-top: 16px;
        }
      }
    }
  }

  .cookunity__setting-section-buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    text-decoration: underline;

    &:has(.cui-button-primary-outline.cui-button-primary-disabled) {
      text-decoration: none;
    }

    @include mobile {
      text-decoration: none;
      justify-content: flex-end;
      margin-top: 1.125rem;

      &.paused {
        flex-direction: column;
      }
    }

    .cookunity__setting-section-buttons-primary {
      flex-grow: 1;

      @include tablet {
        &:not(.cui-button-primary-outline.cui-button-primary-disabled) {
          text-decoration: underline;
        }
      }
    }

    .cookunity__setting-section-buttons-unpause {
      text-decoration: underline;

      @include mobile {
        text-decoration: none;
        width: 100%;
      }
    }

    .cancel_subscription {
      span {
        color: $cui-black-color;
        cursor: pointer;
        @include font-build(
          $cui-font-family-tt-norms,
          $cui-font-base,
          $cui-line-height-2xl,
          $cui-font-bold
        );

        @include mobile {
          font-size: $cui-font-sm;
          line-height: $cui-line-height-xl;
        }
      }

      @include mobile {
        margin: 0;
      }
    }
  }
}

.cookunity__setting-section-cancellation-warning {
  font-family: 'TT Norms Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;

  margin-top: 20px;
}

.cookunity__pause-error-icon {
  color: $cui-red-500;
}
