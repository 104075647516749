@import './styles/normalize.scss';
@import './scss/variables';
@import './scss/mixins';
@import './scss/brand-colors';

@import './scss/global/page-layout';
@import './scss/global/components';
@import './scss/global/outline-tab';
@import './scss/global/react-tooltip';
@import './scss/global/simple-carousel';
@import './scss/global/scrollbar';
@import './scss/global/layout';
@import './scss/global/margins';
@import './scss/global/paddings';
@import './scss/global/typography';
@import './scss/global/tooltip';

// Cui Rebranding
@import './scss/cui/cui';
@import 'node_modules/swiper/swiper.scss';

#root,
body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
  height: 100%;
  font-family: 'lato';
  text-rendering: geometricPrecision;
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

a {
  color: black;
  text-decoration: none;
}

body {
  @include font-family-lato;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  .ReactModal__Overlay {
    z-index: 1020;
  }

  &.ReactModal__Body--open {
    overflow: hidden;
    @include mobile {
      position: fixed !important;
    }
  }
  &.ReactModal__Body--open {
    overflow: hidden;
    position: static;
  }

  .ReactModal__Content {
    outline: none;
  }

  &.no-scroll {
    overflow: hidden;
  }
}

.App {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 1440px;

  .buttons-container {
    > button {
      background-color: white;
      color: $green-color;
      padding: 5px 10px;
      border-radius: 99999px;
      appearance: none;
      cursor: pointer;
      border: none;
    }
  }
}

.app-routes-container {
  align-items: center;
  // overflow: auto;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
}

button {
  transition: 0.3s;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
  color: inherit;
}

button:disabled {
  pointer-events: none;
}

.intercom-lightweight-app {
  display: none !important;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}

#kustomer-ui-sdk-iframe {
  bottom: 20px;
  right: 0px !important;
  left: 20px !important;
  z-index: calc($z-index-cookunity-modal - 1) !important;
}

.-lock-scroll {
  max-height: 100vh;
  overflow: hidden;
}
