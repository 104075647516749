@import '../../scss/mixins';

.challenge-menu {
  position: absolute;
  right: 24px;
  color: #ffb81c;
  margin: auto;
  z-index: 6;

  &--container {
    position: relative;
  }

  &--button {
    width: 27px;
    height: 27px;
  }

  &__highlight {
    z-index: 7;
  }

  .cui-icon {
    height: 100%;
    width: 100%;

    svg {
      height: 100%;
    }
  }

  @include tablet {
    right: 80px;
  }
}
