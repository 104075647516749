@import 'src/scss/mixins';
@import 'src/scss/cui/variables';
@import 'src/scss/variables';

.household-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 33%;
  height: 90px;
  border: 1px solid $cui-black-color-600;
  border-radius: 8px;
  gap: 8px;
  cursor: pointer;
  color: $cui-black-color;
  @include font-build(
    $cui-font-family-tt-norms,
    $cui-font-sm,
    $cui-line-height-xl,
    $cui-font-bold
  );

  &.selected {
    color: $cui-white-color;
    box-shadow: inset 0 0 0 4px $cui-wine-color-700;
    background: $cui-wine-color-500;
    margin: 0;

    svg path {
      fill: $cookunity-white-color;
    }
  }
}
