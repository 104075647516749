@import '../../../../../scss/mixins';
@import '../../../../../scss/cui/variables';

.unity-pass {
  padding: 0px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;

  .cui-bottom-sheet__header {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 0px;
    border-bottom: none;
  }

  .cancel-container {
    padding: 20px;
    flex: 0;
    position: relative;

    .spinner-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 999;
      border-radius: 10px;
    }

    .content {
      position: relative;
      z-index: 1;
    }

    .title-container {
      flex-direction: column;
      display: flex;
      padding: 15px;
      padding-left: 50px;
      padding-right: 50px;

      .title {
        font-family: Rossanova;
        text-align: center;
        line-height: 44px;
        font-weight: 500;
        font-size: 38px;
      }
    }
    .description-container {
      flex-direction: column;
      color: $cui-gray-color;
      display: flex;
      padding: 25px;
      font-size: 16px;
      font-weight: 450;
      line-height: 24px;
      text-align: center;
    }
    .error {
      margin-top: 0px;
      margin-bottom: 10px;
    }
  }
}
