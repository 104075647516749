@import 'src/scss/mixins';

.carrousel {
  width: 100%;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;

  & > * {
    flex-basis: calc(50% - 8px);
  }

  @include mobile {
    flex-direction: column;
  }
}
