@import '../../../../scss/cui/variables';
@import '../../../../scss/mixins';

.rebranding {
    .cookunity__my_account_form {
        margin: 0.75rem 0;

        .cookunity__form_grid {
            margin: 0 0 2.5rem 0;

            @include mobile {
                margin: 0 0 1.75rem 0;
            }
        }

        .taste_preferences {
            margin: 0 0 2.5rem 0;

            @include mobile {
                margin: 0 0 1.75rem 0;
            }
        }

        .cookunity__form_column {
            padding: 0;
        }

        .cookunity__form_label {
            color: $cui-black-color;

            @include font-build(
                $cui-font-family-tt-norms,
                $cui-font-sm,
                $cui-line-height-xl,
                $cui-font-normal
            );
        }

        .cookunity__form_hint {
            color: $cui-black-color;

            @include font-build(
                $cui-font-family-tt-norms,
                $cui-font-xs,
                $cui-line-height-base,
                $cui-font-normal
            );
        }
    }
}