@import '../../../../scss/mixins';
@import '../../../../scss/cui/variables';

.unity-pass {
  .unity-pass-banner-by-chef {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background: #c7b273;
    font-family: 'TT Norms Pro';
    height: 100px;
    padding-bottom: 20px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 180px;
      object-fit: cover;
    }
  }

  .unity-pass-by-chef {
    font-family: $cui-font-family-tt-norms;
    font-style: italic;
    font-size: 12px;
    color: $cui-black-color-3;
    font-weight: 600;
    margin-top: 5px;
    @include tablet {
      font-size: 14px;
    }
  }
}
