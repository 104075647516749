@import 'src/scss/mixins';
@import 'src/scss/cui/variables';

.completeness-circle {
  &__empty {
    display: flex;
    width: 56px;
    min-width: 56px;
    height: 56px;
    padding: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background-color: $cui-light-blue-200;
    font-size: $cui-font-3xl;
  }

  &__progress {
    height: 56px;
  }
}
