@import 'src/scss/mixins';

.product-based-question {
  width: 100%;
  height: 186px;

  @include mobile {
    width: 100%;
    height: auto;
  }

  & .preferences-dropdown {
    height: 100%;
  }
}
