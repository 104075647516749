@import 'src/scss/mixins';
@import 'src/scss/cui/variables';

.preferences-meals {
  border-top: 1px solid $cui-black-color-200;
  margin-top: 56px;
  padding: 56px 16px 0;
  display: flex;
  flex-direction: column;
  gap: 28px;
  @include mobile {
    padding: 40px 16px;
    margin-top: 40px;
  }

  &__header-title {
    @include font-build(
      $cui-font-family-tt-norms,
      $cui-font-3xl,
      $cui-line-height-4xl,
      $cui-font-bold
    );
  }
  &__header-subtitle {
    color: $cui-black-color-300;
    @include font-build(
      $cui-font-family-tt-norms,
      $cui-font-sm,
      $cui-line-height-xl,
      $cui-font-normal
    );
  }
}
