@import 'src/scss/mixins';
@import 'src/scss/cui/variables';

.membership {
  .benefits-container {
    .spinner-overlay {
      background: rgba(255, 255, 255, 0.8);
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      position: absolute;
      display: flex;
      height: 100%;
      z-index: 999;
      width: 100%;
      left: 0;
      top: 0;
    }

    .benefits-title-container {
      flex-direction: column;
      align-items: center;
      padding: 20px;
      padding-top: 30px;
      padding-bottom: 15px;
      display: flex;

      .title {
        font-family: $cui-font-family-rossanova;
        margin-bottom: 20px;
        text-align: center;
        line-height: 35px;
        font-size: 38px;

        .every-order {
          color: #70707b;
        }
      }

      .subtitle {
        font-family: TT Norms Pro;
        text-align: center;
        line-height: 24px;
        color: #4d4d4f;
        font-weight: 450;
        font-size: 16px;
        width: 85%;
        padding-bottom: 10px;
      }
    }

    .benefits-description-container {
      background-color: #f8f8f8;
      flex-direction: column;
      border-radius: 10px;
      margin-right: 50px;
      margin-left: 50px;
      color: #4d4d4f;
      display: flex;
      padding: 35px;
      padding-top: 15px;

      .benefit-container-title {
        font-family: TT Norms Pro;
        text-align: center;
        font-weight: 700;
        font-size: 16px;
        color: #000;
        padding-top: 13px;
      }

      .benefit-container {
        justify-content: flex-start;
        flex-direction: row;
        margin-top: 30px;
        margin-bottom: 8px;
        display: flex;

        .image {
          justify-content: center;
          align-items: center;
          display: flex;
          background-color: white;
          border-radius: 50%;
          height: 50px;
        }

        .details {
          padding: 20px;
          flex: 1;
        }

        .benefit-details {
          align-items: flex-start;
          flex-direction: column;
          margin-left: 18px;
          display: flex;

          .benefit-title {
            font-family: TT Norms Pro;
            line-height: 28px;
            font-weight: 700;
            text-align: left;
            font-size: 19px;
            color: #000;
          }

          .benefit-description {
            font-family: TT Norms Pro;
            line-height: 24px;
            text-align: left;
            font-weight: 450;
            padding-right: 25px;
            font-size: 16px;
            color: #70707b;
          }
        }
      }
    }

    .footer-container {
      flex-direction: column;
      padding-bottom: 20px;
      margin-top: 15px;
      display: flex;

      .footer-header {
        font-family: TT Norms Pro;
        text-align: center;
        line-height: 22px;
        font-weight: 700;
        font-size: 16px;
        margin-top: 10px;
      }

      .footer-subheader {
        font-family: TT Norms Pro;
        text-align: center;
        line-height: 22px;
        color: #70707b;
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 18px;
      }

      .rejoin {
        font-family: TT Norms Pro;
        font-size: 16px;
        font-weight: 450;
        line-height: 24 px;
        text-align: center;
        padding: 20px;
      }

      .cancel-terms {
        font-family: TT Norms Pro;
        font-size: 14px;
        font-weight: 450;
        line-height: 20px;
        text-align: left;
        margin-top: 10px;
        margin-bottom: 25px;
        color: #4d4d4f;
        text-align: center;

        a {
          color: #4d4d4f;
          font-weight: bold;
          text-decoration: underline;
        }
      }

      .cancel {
        text-decoration: underline;
        flex-direction: column;
        padding-bottom: 25px;
        align-items: center;
        color: #97225a;
        font-weight: 700;
        font-size: 14px;
        cursor: pointer;
        display: flex;
      }
    }
  }

  @include until($tablet) {
    padding-bottom: 0;

    .benefits-container {
      .spinner-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
        border-radius: 10px;
      }

      .benefits-title-container {
        flex-direction: column;
        display: flex;

        .title {
          font-family: Rossanova;
          font-size: 26px;
          text-align: center;
          padding-bottom: 3px;
          margin-bottom: 0px;

          .every-order {
            color: #70707b;
          }
        }

        .subtitle {
          font-family: TT Norms Pro;
          font-size: 14px;
          font-weight: 450;
          text-align: center;
          color: #4d4d4f;
        }
      }

      .benefits-description-container {
        background-color: #f8f8f8;
        flex-direction: column;
        border-radius: 10px;
        margin-top: 8px;
        color: #4d4d4f;
        display: flex;
        padding: 12px;
        margin-right: 10px;
        margin-left: 10px;

        .benefit-container-title {
          font-family: TT Norms Pro;
          margin-bottom: 10px;
          margin-top: 5px;
          text-align: center;
          font-weight: 700;
          font-size: 14px;
          color: #000;
        }

        .benefit-container {
          justify-content: flex-start;
          flex-direction: row;
          display: flex;
          margin-bottom: 5px;
          margin-top: 10px;

          .image {
            justify-content: center;
            align-items: center;
            display: flex;
            background-color: white;
            border-radius: 50%;
            height: 50px;
          }

          .details {
            padding: 20px;
            flex: 1;
          }

          .benefit-details {
            align-items: flex-start;
            flex-direction: column;
            margin-left: 10px;
            display: flex;

            .benefit-title {
              font-family: TT Norms Pro;
              font-size: 18px;
              font-weight: 700;
              line-height: 25px;
              text-align: left;
              color: #000;
            }

            .benefit-description {
              font-family: TT Norms Pro;
              font-size: 14px;
              font-weight: 450;
              line-height: 20px;
              text-align: left;
              color: #70707b;
            }
          }
        }
      }
    }

    .footer-container {
      flex-direction: column;
      margin-top: 10px;
      display: flex;
      padding-left: 10px;
      padding-right: 10px;

      .footer-header {
        font-family: TT Norms Pro;
        text-align: center;
        font-weight: 700;
        font-size: 14px;
      }

      .footer-subheader {
        font-family: TT Norms Pro;
        margin-bottom: 5px;
        text-align: center;
        line-height: 20px;
        color: #4d4d4f;
        font-weight: 700;
        font-size: 13px;
      }

      .rejoin {
        font-family: TT Norms Pro;
        font-size: 14px;
        font-weight: 450;
        line-height: 20px;
        text-align: center;
        padding-bottom: 10px;
      }

      .cancel-terms {
        font-family: TT Norms Pro;
        font-size: 14px;
        font-weight: 450;
        line-height: 20px;
        text-align: left;
        margin-top: 8px;
        margin-bottom: 15px;
        color: #4d4d4f;

        a {
          color: #4d4d4f;
          font-weight: bold;
          text-decoration: underline;
        }
      }

      .cancel {
        text-decoration: underline;
        flex-direction: column;
        align-items: left;
        color: #97225a;
        font-weight: 700;
        font-size: 14px;
        cursor: pointer;
        display: flex;
      }
    }
  }
}