@import 'src/scss/mixins';
@import 'src/scss/cui/variables';

.membership {
  .membership-confirmation {
    .title-container {
      flex-direction: column;
      padding: 15px;
      display: flex;

      .title {
        font-family: Rossanova;
        font-size: 32px;
        font-weight: 500;
        line-height: 42px;
        text-align: center;
      }

      .subtitle {
        font-family: TT Norms Pro;
        font-size: 16px;
        font-weight: 450;
        line-height: 24px;
        text-align: center;
        color: #4d4d4f;
        margin-top: 20px;
      }
    }

    .confirmation-buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 20px;
      padding-top: 20px;

      .cui-button-primary-solid {
        background-color: black;
        border: black;
        color: white;
        height: 50px;
        width: 20%;
      }
    }
  }

  @include until($tablet) {
    .membership-confirmation {
      .title-container {
        display: flex;
        flex-direction: column;
        padding-top: 10px;
        padding-left: 15px;
        padding-right: 15px;

        .title {
          font-family: Rossanova;
          font-size: 35px;
          font-weight: 500;
          line-height: 42px;
          text-align: center;
        }

        .subtitle {
          font-family: TT Norms Pro;
          font-size: 15px;
          font-weight: 450;
          line-height: 24px;
          text-align: center;
          color: #4d4d4f;
          margin-top: 20px;
        }
      }

      .confirmation-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 20px;
        padding-left: 15px;
        padding-right: 15px;

        .cui-button-primary-solid {
          background-color: black;
          border: black;
          color: white;
          height: 50px;
          width: 100%;
        }
      }
    }
  }
}