@import 'src/scss/mixins';
@import 'src/scss/cui/variables';

.focused-input {
  scroll-margin-top: 150px;
  outline: 4px solid $cui-wine-color-500;
  border-radius: 16px;
  box-shadow: 0px 0px 6px 0px rgba(35, 31, 32, 0.1),
    0px 2px 6px 0px rgba(35, 31, 32, 0.05);
  @include mobile {
    scroll-margin-top: 450px;
  }
}
