@import 'src/scss/cui/variables';

.icon {
  &.full-star path {
    fill: #ffc84e;
    stroke: #ffc84e;
  }

  &.full-heart path {
    fill: $cui-black-color;
    stroke: $cui-black-color;
  }
}
