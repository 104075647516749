@import '../../../../scss/mixins';

.change-plan-modal {
  padding-top: 31px !important;

  &--title {
    text-align: center;
    font-family: TT Norms Pro;
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;

    @include tablet {
      font-family: Rossanova;
      font-size: 36px;
      line-height: 44px;
    }
  }

  &--form {
    padding-top: 13px;

    & > .cui-spinner {
      margin: auto;
      display: flex;
    }

    @include tablet {
      padding-top: 43px;
    }

    @include desktop {
      padding-left: 54px;
      padding-right: 54px;
    }
  }

  .change-plan {
    &--option-container {
      display: flex;
      flex-direction: column;
      gap: 16px;
      border: none;
      padding: 0;
      margin: 0;

      @include tablet {
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 37px;
        column-gap: 42px;
      }
    }

    &--footer {
      display: flex;
      flex-direction: column;
      gap: 27px;
      margin-top: 24px;

      @include tablet {
        flex-direction: row-reverse;
      }
    }

    &--option {
      input[type='radio'] {
        display: none;
      }

      @include tablet {
        width: calc(50% - 21px);
      }
    }

    &--set-default-plan {
      margin-top: 28px;
    }
  }

  @include tablet {
    padding-top: 69px !important;
  }

  @include desktop {
    &.large_size {
      width: 892px !important;
    }
  }
}
