@import '../../scss/mixins';
@import '../../scss/variables';

footer {
  padding: 65px 0 80px;
  background-color: #231f20;
  position: relative;
  color: #ffffff;

  @media screen and (max-width: 780px) {
    padding: 25px 0 74px;
  }

  .mobile-p {
    display: none;
    @media screen and (max-width: 780px) {
      display: block;
    }
  }

  .flex-end {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    width: 25%;
    flex-direction: column;

    @media screen and (max-width: 780px) {
      width: 100%;
      justify-content: space-between;
    }

    .row-mobile {
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 780px) {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
      }
    }

    li {
      span {
        color: #ffffff;
      }
    }

    .line-social {
      text-decoration: underline;
    }

    .recaptcha-terms {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #ffffff;
    }
  }

  .flex-start {
    // width: 75%;
    float: left;
    display: flex;
    margin-bottom: 0;
    @media screen and (max-width: 780px) {
      width: 100%;
      float: none;
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;
    }

    .row {
      margin-bottom: 0;
      display: flex;
      @media screen and (max-width: 780px) {
        display: flex;
        margin-bottom: 40px;
      }
    }
  }

  .column-footer {
    width: 25%;
    text-align: right;
    // float: left;
    @media screen and (max-width: 780px) {
      width: 120px;
    }
  }

  .column-none {
    display: block;
    @media screen and (max-width: 780px) {
      display: none;
    }
  }

  .column-mobile {
    display: none;
    @media screen and (max-width: 780px) {
      display: block;
    }
  }

  .flex-row {
    text-align: left;
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 80px;

    @media screen and (max-width: 780px) {
      display: none;
    }

    .first-check-column {
      margin-left: 60px;
    }

    p {
      display: inline;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 24px;
      color: #ffffff;
      margin: 0;
    }

    span {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 24px;
      color: #ffffff;
      margin-right: 6px;
    }

    i {
      margin-right: 21px;
      color: #ffffff;
    }
  }

  .row {
    max-width: 1230px;
    padding: 0;
    margin: 0 auto;
    flex: flex;
    flex-direction: row;
    padding: 0;
    @media screen and (max-width: 780px) {
      display: flex;
      flex-direction: column;
      padding: 0 56px 0 20px;
    }

    .column {
      display: flex;
      flex-direction: column;
      width: 78%;

      @media screen and (max-width: 780px) {
        width: 100%;
      }
    }

    .ul-footer {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        margin-top: 15px;
        text-align: left;

        span.footer-link {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          color: #ffffff;
          cursor: pointer;
        }

        a {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          color: #ffffff;
          cursor: pointer;

          .cookunity-app-store {
            max-width: 120px;
            margin-top: 36px;

            @media screen and (max-width: 780px) {
              max-width: 118px;
              margin-top: 26px;
            }
          }

          .cookunity-play-store {
            width: 137px;
            margin-inline-start: -8px;
          }
        }

        &.recaptcha-terms {
          a {
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }
  }
  display: block;
}

#report-bug-modal {
  display: none;
  width: 350px;
  border: 1px solid #ccc;
  background: white;
  padding: 20px;

  label {
    display: block;
    margin: 10px 0;
    color: $green-color;
  }

  select,
  textarea {
    border: 0;
    border-bottom: 1px solid $green-color;
    padding: 5px;
    width: calc(100% - 10px);
    background: white;
  }

  textarea {
    height: 200px;
    padding: 0 5px;
    line-height: 29px;
    background: linear-gradient(
      to bottom,
      #f8f8f8,
      #f8f8f8 50%,
      #ffffff 50%,
      #ffffff
    );
    background-size: 100% 59px;
  }
  .msg {
    margin-top: 10px;
    color: $green-color;
    font-size: 0.9em;
  }
}

// Add some padding if delivery-status is present
.delivery-status {
  + footer {
    li.recaptcha-terms {
      padding-bottom: 72px;
    }
  }
}

// Hides the recaptcha logo
.grecaptcha-badge {
  visibility: hidden;
}
