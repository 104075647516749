@import 'src/scss/_variables';
@import 'src/scss/mixins';

.rating-detail {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include until($tablet) {
    gap: 8px;
    height: 535px;
    justify-content: space-between;
  }

  @include from($tablet) {
    padding-top: 24px;
  }

  &__section {
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    @include until($tablet) {
      padding: 0 16px;
    }
  }

  &__title-section,
  &__reasons-error {
    text-align: left !important;
  }

  &__reasons-error:not(.visible) {
    opacity: 0;
  }

  &__reasons-section {
    margin-top: 16px;
  }

  &__review-section {
    textarea {
      border: 1px solid #eeeeee;
      box-shadow: 0 2px 6px 0 #231f200d;
      background-color: $cookunity-gray-5;
    }
  }

  &__review-textarea {
    height: 120px;
    margin-bottom: 0;
  }
}
