@import 'src/scss/mixins';

.loading-indicator {
  position: relative;
  width: 100%;
  height: calc(100vh - 72px);

  .loader-container {
    position: absolute;
  }

  @include desktop {
    min-height: 600px;
  }
}
