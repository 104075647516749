@import 'src/scss/variables';
@import 'src/scss/mixins';
@import 'src/scss/cui/variables';

.preferences-dropdown {
  border-radius: 16px;
  padding: 16px;
  box-shadow: 0 2px 6px 0 rgba(35, 31, 32, 0.05),
    0 0 6px 0 rgba(35, 31, 32, 0.1);
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 184px;
  justify-content: space-between;
  height: auto;
  background-color: $cookunity-white;

  &.with-yes-no-options-placeholder {
    gap: 0;

    @include mobile {
      gap: 16px;
    }
  }
  &:not(.with-yes-no-options-placeholder) &__yes-no-options-placeholder {
    display: none;
  }

  &__yes-no-options-placeholder {
    min-height: 52px;

    @include mobile {
      display: none;
    }
  }

  &__list {
    max-width: 100%;
  }

  &__selector-check {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .cui-checkbox {
    cursor: pointer;
    width: max-content;
    input {
      cursor: pointer;
    }
  }

  &__input {
    min-width: 304px;
    border-radius: 8px;
    border: 1px solid $cui-black-color-200;
    background: $cui-white-color;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    z-index: 4;
    position: relative;

    &__container {
      display: flex;
      flex-direction: column;
      padding: 16px;
      gap: 12px;
    }

    &-selected-list {
      display: flex;
      flex-direction: row;
      gap: 4px;
      flex-wrap: wrap;
      @include mobile {
        padding: 0;
      }
    }
  }

  &__input-selected-list-item {
    cursor: pointer;
    display: flex;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 200px;
    border: 1px solid $cui-black-color;
    @include font-build(
      $cui-font-family-tt-norms,
      $cui-font-sm,
      $cui-line-height-xl,
      $cui-font-bold
    );
    img {
      width: 16px;
      height: 16px;
    }
  }

  &__input-selected-list-item:hover {
    background-color: $cui-black-color-100;
  }

  &__multivalue-label {
    border: none;
  }
}

.preferences-dropdown-select {
  &__placeholder {
    color: $cui-black-color-350 !important;
    font-family: $cui-font-family-tt-norms !important;
    font-size: $cui-font-sm !important;
    font-weight: $cui-font-normal !important;
    display: inline-block !important;
  }

  &__single-value {
    color: $cui-wine-color-500 !important;
    font-family: $cui-font-family-tt-norms !important;
    font-size: $cui-font-sm !important;
    font-weight: $cui-font-bold !important;
  }

  &__control {
    cursor: pointer !important;
    &--is-focused {
      border: 1px solid $cui-black-color-200 !important;
      box-shadow: none !important;
    }

    &--menu-is-open {
      border: 1px solid $cui-black-color-200 !important;
      box-shadow: none !important;
    }

    &--is-disabled {
      .preferences-dropdown__multivalue-list-item {
        color: $cui-black-color-300 !important;
      }
    }
  }

  &__option {
    color: $cui-black-color-350 !important;
    font-family: $cui-font-family-tt-norms !important;
    font-weight: $cui-font-normal !important;
    font-size: $cui-font-sm !important;
    padding: 8px 16px !important;
    cursor: pointer !important;

    &--is-focused {
      background-color: $cui-black-color-200 !important;
    }

    &--is-selected {
      background-color: $cui-black-color-200 !important;
    }
  }

  &__value-container {
    color: $cui-wine-color-500 !important;
    font-size: $cui-font-sm !important;
    font-family: $cui-font-family-tt-norms !important;
    font-weight: $cui-font-bold !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    display: flex !important;
    flex-wrap: nowrap !important;

    > div {
      vertical-align: middle;
    }

    &--is-multi {
      display: inline-block !important;
      position: relative !important;
    }
  }

  &__multi-value {
    display: flex;
    align-items: center;

    padding-inline-start: 4px;

    background-color: $cui-white-color !important;

    &--is-disabled {
      background-color: $cui-black-color-100 !important;
      border: 1px solid $cui-black-color-300;
      div {
        background-color: $cui-black-color-100;
      }
    }
  }

  &__input-container {
    color: $cui-black-color-350 !important;
    font-family: $cui-font-family-tt-norms !important;
    font-weight: $cui-font-normal !important;
  }

  &__multi-value__label {
    padding-left: 0;
    font-size: $cui-font-sm;
    font-family: $cui-font-family-tt-norms;
    font-weight: $cui-font-bold;
    border: none;
  }

  &__control--menu-is-open {
    .preferences-dropdown__multivalue-list-item {
      display: none !important;
    }
  }

  &__menu-list {
    padding-top: 0 !important;
  }
}

.preferences-bottom-sheet {
  &__header {
    padding: 16px 16px !important;
    border-bottom: 1px solid #eeeeee;

    .cui-bottom-sheet__header__title {
      @include font-build(
        $cui-font-family-tt-norms,
        $cui-font-base,
        $cui-line-height-2xl,
        $cui-font-bold
      );
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 32px;
    max-height: 80vh;
    min-height: 60vh;
    padding: 16px !important;

    .preferences-item__header {
      gap: 16px;

      &-subtitle {
        color: #000000;
        @include font-build(
          $cui-font-family-tt-norms,
          $cui-font-base,
          $cui-line-height-2xl,
          $cui-font-normal
        );
      }
    }

    &.multiple-selection {
      gap: 16px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-height: 100%;
    overflow: auto;
    margin-top: 16px;
  }

  &__list-item {
    cursor: pointer;
    @include font-build(
      $cui-font-family-tt-norms,
      $cui-font-sm,
      $cui-line-height-xl,
      450
    );
  }
}
