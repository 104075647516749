@import 'src/scss/mixins';
@import 'src/scss/cui/variables';

.membership {
  .pause-container {
    padding: 20px;
    padding-bottom: 10px;
    flex: 0;
    position: relative;

    .spinner-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 999;
      border-radius: 10px;
    }

    .content {
      position: relative;
      z-index: 1;
    }

    .title-container {
      flex-direction: column;
      display: flex;
      padding: 15px;
      padding-left: 50px;
      padding-right: 50px;

      .title {
        font-family: Rossanova;
        text-align: center;
        line-height: 44px;
        font-weight: 500;
        font-size: 38px;
      }
    }

    .description-container {
      flex-direction: column;
      color: #4d4d4f;
      display: flex;
      padding: 25px;
      font-size: 16px;
      font-weight: 450;
      line-height: 24px;
      text-align: center;
    }

    .error {
      margin-top: 0px;
      margin-bottom: 10px;
    }

    .footer-container {
      flex-direction: column;
      margin-top: 15px;
      gap: 5px;
      display: flex;
      width: 100%;
      align-items: center;

      .cancel {
        line-height: 20px;
        text-align: left;
        margin-top: 10px;
        margin-bottom: 25px;
        color: #4d4d4f;

        a {
          color: #4d4d4f;
          font-weight: bold;
          text-decoration: underline;
        }
      }
    }

    @include until($tablet) {
      position: relative;

      .spinner-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
        border-radius: 10px;
      }

      .content {
        position: relative;
        z-index: 1;
      }

      .title-container {
        flex-direction: column;
        display: flex;

        .title {
          font-family: TT Norms Pro;
          font-size: 24px;
          font-weight: 700;
          line-height: 32px;
          text-align: center;
        }
      }

      .description-container {
        flex-direction: column;
        border-radius: 10px;
        margin-right: 10px;
        margin-left: 10px;
        color: #4d4d4f;
        display: flex;
        padding: 10px;
        line-height: 24px;

        .accumulated {
          margin-top: 20px;
          font-family: TT Norms Pro;
          font-size: 450;
          color: black;
          font-weight: bold;
          line-height: 24px;
          text-align: center;
        }
      }

      .error {
        margin-top: 0px;
      }
    }
    .button-container {
      flex-direction: column;
      justify-content: center;
      display: flex;
      .primary-button {
        background-color: black;
        border: black;
        color: white;
        height: 40px;
        margin-bottom: 10px;
      }
    }
  }
}