@import 'src/scss/variables';
@import 'src/scss/cui/variables';
@import 'src/scss/mixins';

.meal-rating-card {
  width: 100%;
  padding: 0;
  border-radius: 8px;
}

.rating-detail-bottom-sheet {
  width: 639px !important;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include from($tablet) {
    button.close {
      display: none;
    }
  }

  @include until($tablet) {
    .cui-bottom-sheet__overlay .cui-bottom-sheet__sheet {
      padding-bottom: 0;

      .cui-bottom-sheet__header {
        border-bottom: none;
      }
    }
  }

  .meal-rating-card-detail {
    box-shadow: none;
  }
}
