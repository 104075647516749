@import '../cui/variables';
@import '../mixins';
@import '../variables';

.page__container {
  display: flex;
  min-height: 100%;
  flex-direction: column;
}

.page__header {
  background-color: $cookunity-black-secondary;
  width: 100%;
}

.page__header--sticky {
  position: sticky;
  top: 0px;
  z-index: $z-index-header-desktop;
}

.page__main {
  width: 100%;
  flex: 1 1 0%;
}

.page__horizontal-space {
  margin: auto;
  max-width: 77rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.page__vertical-space {
  padding-top: 30px;
  padding-bottom: 30px;

  @include tablet {
    padding-top: 48px;
    padding-bottom: 80px;
  }
}

.page__block__title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  font-family: $cui-font-family-tt-norms;
  margin-bottom: 16px;

  @include tablet {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 32px;
  }
}
