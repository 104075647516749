@import 'src/scss/_variables';
@import 'src/scss/mixins';

.tags-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;

  &__tag {
    height: 26px;
    border: 1px solid $cookunity-gray-3;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 9px;
    cursor: pointer;

    &.selected {
      border-color: $cookunity-primary-wine;
    }

    @include until($tablet) {
      height: 24px;
    }
  }

  &__tag-label {
    width: max-content;
  }
}
