@import 'src/scss/mixins';
@import 'src/scss/cui/variables';

.progress-bar {
  &__container {
    border-radius: 8px;
    background: $cui-black-color;
    display: flex;
    width: 100%;
    padding: 12px;
    justify-content: space-between;
    align-items: center;
    color: $cui-white-color;

    @include mobile {
      flex-direction: column;
      gap: 16px;
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
  }

  &__title {
    @include font-build(
      $cui-font-family-tt-norms,
      $cui-font-base,
      $cui-line-height-2xl,
      $cui-font-bold
    );
  }

  &__subtitle {
    @include font-build(
      $cui-font-family-tt-norms,
      $cui-font-base,
      $cui-line-height-2xl,
      $cui-font-normal
    );
  }

  &__cta {
    @include mobile {
      width: 100%;
      button {
        min-width: 100%;
      }
    }
  }
}
