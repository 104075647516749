@import '../../../scss/cui/variables';
@import '../../../scss/mixins';

.regular-bundle-card {
  height: 100%;
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  cursor: pointer;
  border-radius: 8px;
  position: relative;
  background-color: $cui-white-color;
  border: 1px solid $cui-black-color-100;

  &-sold-out {
    opacity: 0.6;
  }

  .bundle-chef-image-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .bundle-chef-row-container {
    padding: 12px 0 0 12px;
  }

  .bundle-img-wrapper {
    position: relative;
    height: 260px;
    min-height: 260px;

    &--new-home-layout {
      height: 170px;
      min-height: 170px;
    }

    .bundle-tags-wrapper {
      position: absolute;
      top: 16px;
      z-index: 1;
      left: 16px;
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
      right: 40px;

      .bundle-tag {
        padding: 2px 4px;
        height: 20px;
        @include font-build(
          $cui-font-family-tt-norms,
          $cui-font-xs,
          $cui-line-height-base,
          $cui-font-bold
        );
        &.bundle-tag-with-icon {
          svg {
            height: 12px;
            width: 12px;
            path {
              fill: $cui-white-color;
            }
          }
        }
      }
    }

    .bundle-card-img {
      height: 100%;
      overflow: hidden;
      background: url('../../../assets/img/image-placeholder.png');
      background-size: cover;
      background-position: center;
      img {
        transition: transform 0.5s ease;
      }
    }

    &:before {
      content: '';
      position: absolute;
      pointer-events: none;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        180deg,
        #f9f9f9 0.21%,
        rgba(249, 249, 249, 0) 10%
      );
      background-blend-mode: multiply, normal;
      z-index: 1;
    }
  }

  .bundle-details {
    height: 100%;
    padding: 0 12px 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .bundle-details-line {
      display: flex;
      align-items: center;
      gap: 8px;

      .bundle-products-text {
        @include font-build(
          $cui-font-family-tt-norms,
          $cui-font-sm,
          $cui-line-height-base,
          $cui-font-bold
        );
        margin: 0;
      }
    }

    .regular-bundle-card-sold-out-text {
      background-color: $cui-black-color-350;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 9px;
      color: $cui-white-color;
      margin-top: auto;
      @include font-build(
        $cui-font-family-tt-norms,
        $cui-font-sm,
        $cui-line-height-xl,
        $cui-font-bold
      );
    }

    .bundle-actions-container {
      margin-top: auto;
      padding: 0 16px;
      .cui-meal-actions {
        margin-top: 0;
      }
    }

    .bundle-summary-container {
      &--new-home-layout {
        .cui-meal-summary {
          margin: 0;
          padding: 0;
        }
      }
    }
  }

  &:hover {
    box-shadow: 0px 0px 6px rgba(35, 31, 32, 0.1),
      0px 2px 6px rgba(35, 31, 32, 0.05);
    .bundle-card-img img {
      transform: scale(1.2);
    }
  }
}
