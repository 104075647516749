@import 'src/scss/mixins';
@import 'src/scss/cui/variables';

.preferences-allergies {
  height: 100%;
  border-radius: 16px;
  padding: 16px;
  box-shadow: 0 2px 6px 0 rgba(35, 31, 32, 0.05),
    0 0 6px 0 rgba(35, 31, 32, 0.1);
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__list {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    justify-content: center;
    overflow: scroll;
  }

  .cui-checkbox {
    cursor: pointer;
    width: max-content;
    input {
      cursor: pointer;
    }
  }

  &__item {
    cursor: pointer;
    color: $cui-black-color;
    @include font-build(
      $cui-font-family-tt-norms,
      $cui-font-sm,
      $cui-line-height-xl,
      $cui-font-bold
    );
    border-radius: 200px;
    border: 1px solid $cui-black-color-600;
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    min-width: max-content;

    &.selected {
      color: $cui-white-color;
      box-shadow: inset 0px 0px 0px 4px $cui-wine-color-700;
      background: $cui-wine-color-500;
      margin: 0;
    }
  }

  &__item:hover {
    background-color: $cui-black-color-150;
  }

  &__item.selected:hover {
    background-color: $cui-wine-color-700;
  }
}
