@import 'src/scss/cui/variables';
@import 'src/scss/mixins';

.chef-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  &__img {
    width: 32px;
    height: 32px;
    border-radius: 100px;
    object-fit: cover;
  }

  &__text {
    text-align: left !important;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
  }
}
