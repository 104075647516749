@import 'src/scss/mixins';
@import 'src/scss/cui/variables';

.membership {
  .checkout-container {
    flex-direction: column;
    position: relative;
    padding-top: 20px;
    display: flex;

    .spinner-overlay {
      background: rgba(255, 255, 255, 0.8);
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      position: absolute;
      display: flex;
      height: 100%;
      z-index: 999;
      width: 100%;
      left: 0;
      top: 0;
    }

    .content {
      position: relative;
      z-index: 1;
    }

    .title {
      font-family: Rossanova;
      text-align: center;
      line-height: 44px;
      font-size: 30px;
    }
  }

  .summary-container {
    border: 1px solid rgba(79, 79, 77, 0.2);
    flex-direction: column;
    margin-right: 30px;
    border-radius: 8px;
    margin-left: 30px;
    margin-top: 20px;
    display: flex;
    padding: 25px;

    .title {
      font-family: TT Norms Pro;
      align-items: flex-start;
      margin-bottom: 20px;
      line-height: 24px;
      font-weight: 700;
      text-align: left;
      font-size: 20px;
      display: flex;
    }

    .detail {
      justify-content: space-between;
      flex-direction: row;
      margin-bottom: 10px;
      display: flex;
    }

    .summary-description {
      font-family: TT Norms Pro;
      align-items: flex-start;
      flex-direction: column;
      text-align: justify;
      position: relative;
      margin-left: 20px;
      line-height: 20px;
      text-align: left;
      font-weight: 450;
      font-size: 14px;
      display: flex;
      color: #4d4d4f;

      .first {
        margin-bottom: 5px;

        &::before {
          position: absolute;
          content: '•';
          left: -1em;
        }
      }

      .second {
        &::before {
          position: absolute;
          content: '•';
          left: -1em;
        }
      }
    }

    .dotted-line {
      border: none;
      border-top: 1px dotted #dddddd;
      margin: 20px 0;
    }

    .summary-footer {
      justify-content: space-between;
      flex-direction: row;
      display: flex;
      font-family: TT Norms Pro;
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      text-align: left;
    }
  }

  .terms {
    font-family: TT Norms Pro;
    font-size: 14px;
    font-weight: 450;
    line-height: 20px;
    text-align: left;
    margin-left: 35px;
    margin-right: 30px;
    margin-top: 20px;
    color: #4d4d4f;

    a {
      color: #4d4d4f;
      font-weight: bold;
      text-decoration: underline;
    }
  }

  .checkout-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 25px;
    padding-top: 15px;
    margin-top: 20px;

    .cui-button-primary-solid {
      background-color: black;
      border: black;
      color: white;
      height: 50px;
      width: 30%;
    }
  }

  .error {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border: 2px solid #ee5744;
    border-radius: 4px;
    margin: 30px;
    margin-top: 15px;
    margin-bottom: -12px;
    padding: 15px;
    color: #ee5744;

    .alert-error {
      height: 16px;
      width: 16px;
      margin-right: 10px;

      path {
        fill: #ee5744;
      }
    }
  }

  @include until($tablet) {
    .checkout-container {
      flex-direction: column;
      display: flex;
      position: relative;
      padding-left: 15px;
      padding-right: 15px;

      .spinner-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
        border-radius: 10px;
      }

      .content {
        position: relative;
        z-index: 1;
      }

      .title {
        font-family: Rossanova;
        text-align: center;
        line-height: 44px;
        font-weight: 500;
        font-size: 32px;
      }
    }

    .summary-container {
      border: 1px solid rgba(79, 79, 77, 0.2);
      flex-direction: column;
      border-radius: 8px;
      margin-right: 5px;
      margin-left: 5px;
      margin-top: 20px;
      display: flex;
      padding: 25px;

      .title {
        font-family: TT Norms Pro;
        align-items: flex-start;
        margin-bottom: 20px;
        line-height: 24px;
        font-weight: 700;
        text-align: left;
        font-size: 20px;
        display: flex;
      }

      .detail {
        justify-content: space-between;
        flex-direction: row;
        margin-bottom: 10px;
        display: flex;
      }

      .summary-description {
        font-family: TT Norms Pro;
        align-items: flex-start;
        flex-direction: column;
        text-align: justify;
        position: relative;
        margin-left: 20px;
        line-height: 20px;
        text-align: left;
        font-weight: 450;
        font-size: 14px;
        display: flex;
        color: #4d4d4f;

        .first {
          margin-bottom: 5px;

          &::before {
            position: absolute;
            content: '•';
            left: -1em;
          }
        }

        .second {
          &::before {
            position: absolute;
            content: '•';
            left: -1em;
          }
        }
      }

      .dotted-line {
        border: none;
        border-top: 1px dotted #dddddd;
        margin: 20px 0;
        /* Adjust as needed */
      }

      .summary-footer {
        justify-content: space-between;
        flex-direction: row;
        display: flex;
        font-family: TT Norms Pro;
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        text-align: left;
      }
    }

    .terms {
      font-family: TT Norms Pro;
      font-size: 14px;
      font-weight: 450;
      line-height: 20px;
      text-align: left;
      margin-left: 7px;
      margin-right: 7px;
      margin-top: 15px;
      color: #4d4d4f;

      a {
        color: #4d4d4f;
        font-weight: bold;
        text-decoration: underline;
      }
    }

    .checkout-buttons {
      display: flex;
      justify-content: center;

      .cui-button-primary-solid {
        background-color: black;
        border: black;
        color: white;
        height: 50px;
        width: 100%;
      }
    }

    .error {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      border: 2px solid #ee5744;
      border-radius: 4px;
      margin: 2%;
      margin-top: 10px;
      margin-bottom: -12px;
      padding: 10px;
      color: #ee5744;

      .alert-error {
        height: 16px;
        width: 16px;
        margin-right: 10px;

        path {
          fill: #ee5744;
        }
      }
    }
  }
}