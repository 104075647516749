@import '../../scss/cui/variables';
@import '../../scss/mixins';

.chefs-row-container {
  display: flex;
  align-items: center;

  .chef-row-images {
    display: flex;
    .-offset {
      &-small {
        margin-right: -6px;
      }
      &-medium {
        margin-right: -10px;
      }
      &-large {
        margin-right: -24px;
      }
    }
    .-no-offset {
      &-small {
        margin-right: 6px;
      }
      &-medium {
        margin-right: 10px;
      }
      &-large {
        margin-right: 24px;
      }
    }
  }

  .chef-row-title {
    &.-light {
      color: $cui-white-color;
    }

    &.-dark {
      color: $cui-black-color;
    }

    &.-small {
      @include font-build(
        $cui-font-family-tt-norms,
        $cui-font-xxs,
        $cui-line-height-xs,
        $cui-font-normal
      );
    }
    &.-medium {
      @include font-build(
        $cui-font-family-tt-norms,
        $cui-font-xs,
        $cui-line-height-base,
        $cui-font-bold
      );
    }

    &.-large {
      @include font-build(
        $cui-font-family-tt-norms,
        $cui-font-base,
        $cui-line-height-2xl,
        $cui-font-bold
      );
    }
  }
}
