@import '../../scss/mixins';
@import '../../scss/cui/variables';

.membership-steps {

  .unity-pass-buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 15px;

    .cui-button-primary-solid {
      background-color: $cui-black-color-0;
      border: $cui-black-color-0;
      color: $cui-white-color;
      height: 35px;
      width: 100%;

      @include tablet {
        width: 300px;
      }

      &:hover {
        background-color: $cui-black-color-3;
        color: $cui-white-color;
      }
    }
  }

  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    @include tablet {
      flex-direction: row !important;
    }

    .primary-button {
      background-color: $cui-black-color-0;
      border: $cui-black-color-0;
      color: $cui-white-color;
      height: 35px;
      width: 100%;

      @include tablet {
        width: auto;
      }

      &:hover {
        background-color: $cui-black-color-3;
        color: $cui-white-color;
      }
    }

    .secondary-button {
      background-color: $cui-white-color;
      border: 1px solid $cui-black-color-0;
      color: $cui-black-color-0;
      height: 35px;
      width: 100%;

      @include tablet {
        width: auto;
      }

      &:hover {
        background-color: $cui-lightgray-color;
        border: 1px solid $cui-black-color-0;
        color: $cui-black-color-0;
      }
    }
  }
}