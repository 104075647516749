@import '../../scss/mixins';
@import '../../scss/variables';

.credit-table {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  padding: 27px 32px;

  &__row {
    display: flex;
    flex-direction: column;
    @include desktop {
      flex-direction: row;
    }
  }

  &__cell {
    padding: 10px;
    flex: 1;

    @include desktop {
      &:first-child {
        text-align: left;
      }

      &:nth-child(2) {
        text-align: center;
      }

      &:last-child {
        text-align: right;
      }
    }
  }

  &__label {
    font-weight: bold;
    display: inline-block;
    margin-right: 5px;

    @include desktop {
      display: block;
      margin-bottom: 20px;
    }
  }

  @include desktop {
    & > * {
      flex: 1;
      padding: 10px;

      &:first-child {
        text-align: left;
      }

      &:nth-child(2) {
        text-align: center;
      }

      &:last-child {
        text-align: right;
      }
    }
  }
}
