@import '../../../../scss/cui/variables';
@import '../../../../scss/mixins';

.rebranding {
  .cookunity__add_partner_modal {
    .title {
      @include font-build(
        $cui-font-family-tt-norms,
        $cui-font-xl,
        $cui-line-height-2xl,
        $cui-font-bold
      );

      @include mobile {
        text-align: center;
      }
    }

    .cookunity__form_column {
      .cookunity__input_field {
        background-color: $cui-white-color;
        color: $cui-black-color-350;
        border: 1px solid $cui-black-color-200;
        outline-color: $cui-black-color-200;
        border-radius: 4px;

        padding: 10px 12px;
        max-height: $cui-height-input-small;

        @include font-build(
          $cui-font-family-tt-norms,
          $cui-font-sm,
          $cui-line-height-base,
          $cui-font-normal
        );
      }

      .cookunity__form_error_message {
        color: $cui-red-500;
        margin-top: 1rem;

        @include font-build(
          $cui-font-family-tt-norms,
          $cui-font-xs,
          $cui-line-height-base,
          $cui-font-bold
        );
      }
    }

    .cookunity__button_row {
      margin-top: 16px;
      button {
        @include mobile {
          margin-top: 1rem;
        }
      }
    }
  }
}
